:root {
	// Font family ***
	--exo: "Exo", sans-serif;
	--inter: "Inter", sans-serif;

	// Colors ***
	--primary-color: #4aab3d;
	--primary-10: rgba(73, 169, 61, 0.1);
	--secondary-color: #1f1f25;
	--sub-bg: #f6f7f9;
	--black-sub: #2a2a30;
	--heading-color: #1f1f25;
	--paragraph: #4f5359;
	--span: #6e777d;
	--border: #e9e9e9;
	--white: #ffffff;
	--black: #1f1f25;

	// border ***
	--border-1px: 1px solid #cccccc;
	--border-2px: 2px solid #e9e9e9;

	// Transitions ***
	--transition: all 0.4s ease-in-out;

	// Box shadow ***
	--shadow: 0px 0px 60px 0px #3a3a3a8f;
	--shadow-xs: 0px 0px 15px 0px rgba(194, 194, 194, 0.26);
}
