@mixin justify {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
@mixin justify-wrp {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}
@mixin flex {
	display: flex;
	align-items: center;
}
@mixin flex-wrp {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
@mixin center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
@mixin radius($width, $height, $line-hight) {
	width: $width;
	height: $height;
	line-height: $line-hight;
	text-align: center;
	border-radius: 50%;
	transition: var(--transition);
}
@mixin bg-image {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin breakpoint($point) {
	@if $point==xsmall {
		@media (min-width: 450px) {
			@content;
		}
	} @else if $point==max-xxsmall {
		@media (max-width: 450px) {
			@content;
		}
	}
	@if $point==xxs {
		@media (min-width: 470px) {
			@content;
		}
	} @else if $point==max-xxs {
		@media (max-width: 470px) {
			@content;
		}
	}
	@if $point==xs {
		@media (min-width: 500px) {
			@content;
		}
	} @else if $point==max-xs {
		@media (max-width: 500px) {
			@content;
		}
	}
	@if $point==sm {
		@media (min-width: 576px) {
			@content;
		}
	} @else if $point==max-sm {
		@media (max-width: 575px) {
			@content;
		}
	} @else if $point==md {
		@media (min-width: 768px) {
			@content;
		}
	} @else if $point==max-md {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point==lg {
		@media (min-width: 992px) {
			@content;
		}
	} @else if $point==max-lg {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point==xl {
		@media (min-width: 1200px) {
			@content;
		}
	} @else if $point==max-xl {
		@media (max-width: 1199px) {
			@content;
		}
	} @else if $point==xxl {
		@media (min-width: 1400px) {
			@content;
		}
	} @else if $point==max-xxl {
		@media (max-width: 1399px) {
			@content;
		}
	} @else if $point==xxxl {
		@media (min-width: 1600px) {
			@content;
		}
	} @else if $point==max-xxxl {
		@media (max-width: 1600px) {
			@content;
		}
	} @else if $point==x4l {
		@media (min-width: 1800px) {
			@content;
		}
	} @else if $point==max-x4l {
		@media (max-width: 1800px) {
			@content;
		}
	}
}
