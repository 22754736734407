* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--inter);
  color: var(--paragraph);
  background-color: var(--white);
  line-height: 28px;
  font-weight: 400;
  font-size: 15px;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: var(--heading-color);
  font-family: var(--exo);
}

h1 {
  font-size: 50px;
  font-weight: 700;
  line-height: 70px;
  @include breakpoint(max-lg) {
    font-size: 40px;
    line-height: 50px;
  }
  @include breakpoint(max-md) {
    font-size: 30px;
    line-height: 40px;
  }
}

h2 {
  font-size: 42px;
  font-weight: 700;
  line-height: 52px;
  @include breakpoint(max-md) {
    font-size: 30px;
    line-height: 40px;
  }
}

h3 {
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
}

h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}

h6 {
  font-size: 14px;
  font-weight: 600;
}

p {
  margin: 0;
  padding: 0;
  line-height: 28px;
  font-size: 15px;
}

span {
  display: inline-block;
  color: var(--span);
}

a {
  text-decoration: none;
  display: inline-block;
  color: var(--heading-color);
  transition: var(--transition);
}

a:hover {
  color: var(--heading-color);
}

ul {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

li {
  list-style: none;
}

button {
  border: none;
  background-color: transparent;
}

input {
  border: none;
  outline: none;
}

@include breakpoint(max-lg) {
  br {
    display: none;
  }
}

::selection {
  color: var(--white);
  background-color: var(--primary-color);
}

// typography area end here ***

// Custom class area start here ***
// Margin Top ***
@for $i from 1 through 40 {
  .mt-#{5 * $i} {
    margin-top: 5px * $i;
  }
}

/*-- Margin Bottom --*/
@for $i from 1 through 40 {
  .mb-#{5 * $i} {
    margin-bottom: 5px * $i;
  }
}

// Margin Left ***
@for $i from 1 through 40 {
  .ml-#{5 * $i} {
    margin-left: 5px * $i;
  }
}

// Margin Right***
@for $i from 1 through 40 {
  .mr-#{5 * $i} {
    margin-right: 5px * $i;
  }
}

// Padding Top ***
@for $i from 1 through 40 {
  .pt-#{5 * $i} {
    padding-top: 5px * $i;
  }
}

// Padding Bottom ***
@for $i from 1 through 40 {
  .pb-#{5 * $i} {
    padding-bottom: 5px * $i;
  }
}

//  Padding Left ***
@for $i from 1 through 40 {
  .pl-#{5 * $i} {
    padding-left: 5px * $i;
  }
}

// Padding Right ***
@for $i from 1 through 40 {
  .pr-#{5 * $i} {
    padding-right: 5px * $i;
  }
}

@include breakpoint(max-md) {
  .pt-120 {
    padding-top: 60px;
  }
  .pb-120 {
    padding-bottom: 60px;
  }
}

.bor {
  border: 1px solid var(--border);
}

.bor-top {
  border-top: 1px solid var(--border);
}

.bor-left {
  border-left: 1px solid var(--border);
}

.bor-bottom {
  border-bottom: 1px solid var(--border);
}

.bor-right {
  border-right: 1px solid var(--border);
}

.border-none {
  border: none !important;
}

.text-justify {
  text-align: justify;
}

.image {
  &.--shadow {
    box-shadow: var(--shadow-xs);
    padding: 10px;
    border-radius: 10px;
  }

  &.--small {
    width: 70%;
    @include breakpoint(max-md) {
      width: 100%;
    }
  }

  img {
    width: 100%;
    border-radius: 10px;
  }

  &-wrapper {
    display: flex;
    justify-content: center;
  }
}

.primary-color {
  color: var(--primary-color);
}

.primary-hover {
  &:hover {
    color: var(--primary-color) !important;
  }
}

.primary-bg {
  background-color: var(--primary-color);
}

.secondary-color {
  color: var(--secondary-color);
}

.secondary-bg {
  background-color: var(--secondary-color);
}

.sub-bg {
  background-color: var(--sub-bg);
}

.black-sub {
  background-color: var(--black-sub);
}

.black-para {
  color: var(--white);
  opacity: 0.6;
}

.sm-font {
  font-size: 14px;
}

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.radius10 {
  border-radius: 10px;
}

.transition {
  transition: var(--transition);
}

.overlay {
  // only use for extend
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.nice-select {
  width: 100%;
  border-radius: 10px;
  height: 50px;
  line-height: 50px;

  &:focus {
    border: 1px solid var(--primary-color);
  }

  .list {
    width: 100%;
  }
}

// Custom class area end here ***

// Star area start here ***
.star {
  i {
    color: var(--primary-color);
  }
}

// Star area end here ***

// Pagination area start here ***
.pegi {
  @include flex-wrp;
  gap: 10px;

  a {
    @include radius(60px, 60px, 60px);
    background-color: var(--primary-10);
    color: var(--heading-color);
    font-weight: 700;

    &:hover {
      background-color: var(--primary-color);
      color: var(--white);

      i {
        color: var(--white) !important;
      }
    }

    &.active {
      background-color: var(--primary-color);
      color: var(--white);
    }
  }
}

// Pagination area end here ***

// Preloader area start here ***
#loading {
  background-color: var(--white);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999999;
  margin-top: 0px;
  top: 0px;

  #loading-center {
    width: 100%;
    height: 100%;
    position: relative;
  }

  #loading-center-absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 25%;
    transform: translate(-50%, -50%);
  }

  .loading-icon .loading-logo {
    width: 150px;
    height: 150px;
  }

  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    #loading-center-absolute {
      width: 40%;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    #loading-center-absolute {
      width: 40%;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    #loading-center-absolute {
      width: 45%;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    #loading-center-absolute {
      width: 50%;
    }
  }
  @media (max-width: 575px) {
    #loading-center-absolute {
      width: 60%;
    }
  }
}


.display-6 {
  font-size: 1.4rem !important;
}

// Preloader area end here ***
