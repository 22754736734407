// Banner one area start here ***
.banner {
	&-area {
		padding-top: 100px;
		padding-bottom: 130px;
		position: relative;
		overflow: hidden;

		@include breakpoint(max-lg) {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		&::before {
			background: linear-gradient(
				94.58deg,
				#1f1f25 0.34%,
				rgba(31, 31, 37, 0.79) 98.11%
			);
		}
		@extend.overlay;
	}
	&__right {
		position: absolute;
		left: 50%;
		bottom: 0;
		@include breakpoint(max-lg) {
			display: none;
		}
	}
	&__content {
		position: relative;
		max-width: 652px;
		@include breakpoint(max-xl) {
			max-width: 530px;
		}
		@include breakpoint(max-lg) {
			max-width: 100%;
		}
		h1 {
			color: var(--white);
		}
		p {
			color: var(--white);
			margin-top: 20px;
		}
	}
}
// Banner one area end here ***

// Banner Two area start here ***
.banner-two {
	&-area {
		padding: 150px 0;
		overflow: hidden;
		&::before {
			display: none;
		}
	}
	&__shape {
		position: absolute;
		left: 0;
		bottom: 0;
	}
	&__content {
		margin-left: 10%;
		position: relative;
		max-width: 580px;
		h1 {
			color: var(--heading-color);
			font-size: 60px;
			line-height: 80px;
			@include breakpoint(max-md) {
				font-size: 40px;
				line-height: 50px;
			}
			@include breakpoint(max-xxsmall) {
				font-size: 30px;
				line-height: 40px;
			}
		}
		p {
			color: var(--paragraph);
			margin-top: 20px;
		}
	}
}
// Banner Two area end here ***

// Page banner area start here ***
.page-banner {
	background-image:
		url(../../assets/images/banner/banner-fp.jpg);
	//text-transform: capitalize;
	text-align: center;
	h2,
	a,
	span {
		color: var(--white);
	}
	h2 {
		margin-bottom: 10px;
	}
	a {
		&:hover {
			color: var(--primary-color);
		}
	}
}
// Page banner area end here ***
