// Footer area start here ***
.footer {
	&-area {
		position: relative;
		padding-top: 140px;
	}
	&__bg {
		position: absolute;
		right: 0;
		bottom: 0;
	}
	&__wrp {
		position: relative;
	}
	&__item {
		.footer-subs {
			position: relative;
			input {
				width: 100%;
				border-radius: 10px;
				border: 2px solid var(--black-sub);
				color: var(--white);
				padding: 10px 20px;
				background-color: transparent;
				&:focus {
					border: 2px solid var(--primary-color);
				}
			}
			button {
				padding: 7px 15px;
				color: var(--white);
				background-color: var(--primary-color);
				font-size: 14px;
				border-radius: 7px;
				position: absolute;
				right: 5px;
				top: 5px;
			}
		}
		.social-icons {
			a {
				@include radius(40px, 40px, 40px);
				border-radius: 10px;
				color: var(--white);
				background-color: var(--black-sub);
				margin-right: 5px;
				&:hover {
					color: var(--primary-color);
				}
			}
			.active {
				color: var(--primary-color);
			}
		}
		ul {
			li {
				a {
					color: var(--white);
					&:hover {
						color: var(--primary-color);
					}
					i {
						color: var(--primary-color);
						margin-right: 5px;
					}
				}
			}
		}
		&.about {
			@include breakpoint(max-md) {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				margin-bottom: 20px;
			}
		}
		&.service {
			ul {
				li {
					a {
						&:hover {
							padding-left: 5px;
						}
					}
				}
			}
		}
		&.find {
			ul {
				li {
					a {
						display: flex;
						i {
							font-size: 6px;
							margin-top: 10px;
							padding-right: 5px;
						}
					}
				}
			}
		}
	}
	&__copy-text {
		padding: 15px 0;
		border-top: 1px solid var(--black-sub);
		p {
			color: var(--white);
			opacity: 0.8;
			a {
				color: var(--white);
				font-weight: 500;
				&:hover {
					color: var(--primary-color);
				}
			}
		}
		ul {
			@include flex-wrp;
			li {
				&:not(:last-child) {
					margin-right: 30px;
					@include breakpoint(max-md) {
						margin-right: 10px;
					}
				}
				a {
					color: var(--white);
					opacity: 0.8;
				}
			}
		}
		.copy-text {
			@include breakpoint(max-md) {
				flex-direction: column;
			}
		}
	}
	&-one {
		padding-top: 0;
	}
	&__gallery {
		.footer-popup {
			position: relative;
			border-radius: 10px;
			overflow: hidden;
			.icon {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 1;
				color: var(--primary-color);
				width: 25px;
				height: 25px;
				line-height: 25px;
				border-radius: 50%;
				background-color: var(--white);
				text-align: center;
				transition: var(--transition);
				opacity: 0;
			}
			&::after {
				position: absolute;
				content: "";
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgb(74, 171, 61, 0.7);
				transition: var(--transition);
				opacity: 0;
			}
			&:hover {
				.icon {
					opacity: 1;
				}
				&::after {
					opacity: 1;
				}
			}
		}
	}
}
// Footer area end here ***
