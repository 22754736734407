// Section header area start here ***
.section-header {
  h5 {
    color: var(--primary-color);
    padding-bottom: 5px;
    text-transform: uppercase;
    border-bottom: 1px solid var(--primary-color);
    display: inline-block;
    margin-bottom: 20px;
  }

  h2 {
    //text-transform: capitalize;
  }

  p {
    margin-top: 20px;
  }
}

// Section header area end here ***

// Accordion area start here ***
.accordion {
  .accordion-item {
    border: var(--border-1px);
    border-radius: 10px;
    margin-bottom: 30px;

    h2 {
      button {
        font-size: 15px;
        line-height: 28px;
        font-weight: 700;
        box-shadow: none;
        border-radius: 0 !important;
        padding: 20px 30px;
      }
    }

    .accordion-body {
      padding: 20px 30px;
      padding-top: 0;
    }
  }

  .accordion-button {
    background-color: transparent;
    color: var(--heading-color);

    &::after {
      display: none;
    }

    &::before {
      position: absolute;
      content: "\f068";
      font-family: "Font Awesome 6 Pro";
      font-weight: 700;
      top: 20px;
      right: 25px;
      font-size: 15px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 10px;
      color: var(--white);
      background-color: var(--primary-color);
      text-align: center;
      transition: var(--transition);
    }
  }

  .accordion-button.collapsed {
    background-color: transparent;
    color: var(--heading-color);

    &::before {
      content: "\2b";
      background-color: var(--sub-bg);
      color: var(--primary-color);
    }
  }
}

// Accordion area end here ***

// Service area start here ***
.service {
  &__content {
    p {
      min-height: 105px;
    }
  }

  &__wrp {

    @include breakpoint(max-lg) {
      margin-top: -0px;
    }
  }

  &__title {
    margin-bottom: 80px;
    padding-top: 80px;
    @include breakpoint(max-lg) {
      margin-bottom: 20px;
      padding-top: 40px;
    }
  }

  &__icon {
    @include radius(80px, 80px, 80px);
    border-radius: 10px;
    background-color: var(--primary-color);
    position: absolute;
    left: 40px;
    bottom: 15px;
    transition: var(--transition);

    img {
      max-width: 45px;
      max-height: 45px;
    }
  }

  &__item {
    position: relative;
    background-color: var(--black-sub);
    border-radius: 10px;
    box-shadow: var(--shadow);
    padding: 40px;
    border: 1px solid transparent;
    transition: var(--transition);

    svg {
      fill: #000 !important;
      width: 100px;
    }

    p {
      border-bottom: var(--border-1px);
    }

    &:hover {
      border: 1px solid var(--primary-color);

      .service__icon {
        transform: translate(5px);
      }
    }

    @include breakpoint(max-lg) {
      margin-top: 30px;
    }
  }

  &-details {
    &__image {
      position: relative;

      .image-icon {
        @include radius(60px, 60px, 60px);
        border-radius: 10px;
        background-color: var(--primary-color);
        position: absolute;
        left: 20px;
        top: 20px;

        img {
          width: 45px;
        }
      }
    }

    &__left-item {
      .info {
        padding: 60px 40px;
        border-radius: 10px;
        box-shadow: var(--shadow);

        .call-icon {
          @include radius(55px, 55px, 55px);
          color: var(--white);
          background-color: var(--primary-color);
          margin-bottom: 10px;
        }

        span {
          color: var(--heading-color);
          font-size: 16px;
          font-weight: 500;
          display: inline-block;
          margin-bottom: 5px;
        }
      }

      .left-title {
        position: relative;

        &::after {
          position: absolute;
          content: "";
          width: 70px;
          height: 3px;
          background-color: var(--heading-color);
          left: 0;
          bottom: -5px;
        }
      }

      .service-list {
        border: none;

        li {
          margin-top: 10px;
          width: 100%;

          a {
            padding: 15px 30px;
            color: var(--heading-color);
            background-color: var(--sub-bg);
            border-radius: 10px;
            font-weight: 600;
            display: block;
            border: none;
            text-transform: capitalize;

            i {
              float: right;
              line-height: 30px;
              color: var(--paragraph);
              transition: var(--transition);
            }

            &:hover {
              color: var(--white);
              background-color: var(--primary-color);

              i {
                color: var(--white);
                margin-right: -5px;
              }
            }

            &.active {
              color: var(--white);
              background-color: var(--primary-color);

              i {
                color: var(--white);
                margin-right: -5px;
              }
            }
          }
        }
      }
    }

    &__right-item {
      .subscribe__item {
        h4 {
          span {
            font-size: 15px;
            cursor: pointer;
          }
        }
      }

      .abmin .content::before {
        position: absolute;
        content: "";
        width: 30px;
        height: 30px;
        background-color: var(--white);
        border-left: 1px solid var(--border);
        border-bottom: 1px solid var(--border);
        transform: rotate(45deg);
        top: 40%;
        left: -16px;
      }

      .nav-tabs {
        border: none;
        margin-bottom: 30px;

        .nav-link {
          margin-right: 30px;
          border: none;
          padding: 0;
          padding-bottom: 5px;
          font-weight: 600;
          color: var(--heading-color);
          box-shadow: none;

          &.active {
            border-bottom: 2px solid var(--primary-color);
          }
        }
      }
    }
  }
}

.service-two {
  position: relative;

  &__bg {
    height: 550px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__item {
    position: relative;
    padding: 50px 40px;
    text-align: center;
    background-color: var(--white);
    box-shadow: var(--shadow);
    border-radius: 10px;
    border-bottom: 3px solid transparent;

    &.--equal {
      min-height: 285px;
    }

    .icon {
      @include radius(125px, 125px, 125px);
      background-color: var(--sub-bg);
      margin: 0 auto;
      position: relative;
      transform: translateY(0px);

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 50%;
        bottom: -20px;
        height: 50%;
        right: 33px;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 70px 16px;
        z-index: 1;
        transform: rotate3d(1, 0, 0, 70deg);
        transition: var(--transition);
        opacity: 0;
      }
    }

    &.active {
      border-bottom: 3px solid var(--primary-color);

      .icon {
        transform: translateY(-10px);

        &::after {
          opacity: 1;
        }
      }

      .btn-three {
        background: var(--primary-color);
        color: var(--white);
      }
    }

    &.page-two {
      border-bottom: 2px solid transparent;

      .icon {
        background-color: var(--primary-10);
      }

      &.active {
        border-bottom: 2px solid var(--primary-color);
      }
    }
  }
}

// Service area end here ***

// About area start here ***
.about {
  &-area {
    padding-bottom: 120px;
    @include breakpoint(max-xxl) {
      padding-bottom: 200px;
    }
    @include breakpoint(max-md) {
      padding-bottom: 45px;
    }
  }

  &__image {
    max-width: 571px;
    margin-top: 60px;
    @include breakpoint(max-lg) {
      margin-top: 0;
    }
  }

  &__content {
    max-width: 560px;
    @include justify-wrp;

    ul {
      margin-top: 30px;

      li {
        line-height: 22px;
        font-weight: 600;
        color: var(--black);
        font-family: var(--exo);

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        i {
          color: var(--primary-color);
          margin-right: 10px;
        }
      }
    }
  }
}

// About area end here ***

// Choose area start here ***
.choose {
  &-area {
    padding-bottom: 120px;
    @include breakpoint(max-md) {
      padding-bottom: 60px;
    }
  }

  &__item {
    padding-left: 80px;
    @include breakpoint(max-xl) {
      padding-left: 0;
    }
  }

  &__content {
    .choose-info {
      li {
        @include flex;

        .icon {
          @include radius(100px, 100px, 100px);
          box-shadow: var(--shadow);
          margin-right: 25px;
        }

        p {
          flex-basis: 70%;
        }
      }
    }
  }
}

// Choose area end here ***

// Video area start here ***
.video {
  &-area {
    margin-top: -13%;
  }

  &__image {
    position: relative;
  }

  &__btn-wrp {
    @include center;
  }
}

// Video area end here ***

// Team area start here ***
.team {
  &__image {
    img {
      transition: var(--transition);
    }

    .social-icons {
      @include flex;
      justify-content: center;
      gap: 10px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 25px;

      a {
        font-size: 14px;
        @include radius(35px, 35px, 35px);
        background-color: var(--white);
        border-radius: 5px;
        color: var(--black);
        opacity: 0;
        visibility: hidden;
        transform: rotateY(-90deg);

        &:hover {
          background-color: var(--primary-color);
          color: var(--white);
        }
      }

      .active {
        background-color: var(--primary-color);
        color: var(--white);
      }
    }

    overflow: hidden;
    border-radius: 10px;
    @extend .overlay;

    &::before {
      background: linear-gradient(
          180deg,
          rgba(31, 31, 37, 0) 33.33%,
          #1f1f25 100%
      );
      transition: var(--transition);
      opacity: 0;
    }
  }

  &__item {
    h3 {
      font-weight: 700;
    }

    &:hover {
      .team__image {
        .social-icons {
          a {
            opacity: 1;
            visibility: visible;
            transform: rotateY(0deg);
          }
        }

        &::before {
          opacity: 1;
        }
      }
    }
  }
}

.team-single {
  &__image {
    overflow: hidden;
    position: relative;
    border-radius: 10px;

    img {
      width: 100%;
    }

    .team-info {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 20px;
      background-color: var(--primary-color);
      border-top-right-radius: 10px;

      a {
        margin: 10px 0;
        color: var(--white);

        &:hover {
          color: var(--heading-color);
        }
      }
    }
  }

  &__info {
    .skills {
      .experience-title {
        font-weight: 400;
      }

      .progress {
        .progress-bar {
          background-color: var(--primary-color);
        }

        height: 4px;
      }
    }
  }
}

// Team area end here ***

// Subscribe area start here ***
.subscribe {
  &__item {
    background-color: var(--white);
    box-shadow: var(--shadow);
    border-radius: 10px;
    padding: 40px;

    h5 {
      color: var(--primary-color);
    }

    h2 {
      margin-bottom: 20px;
    }

    form {
      input {
        width: 100%;
        padding: 11px 20px;
        border-radius: 10px;
        border: 1px solid #e1e1e1;
        margin-bottom: 30px;

        &:focus {
          border: 1px solid var(--primary-color);
        }
      }

      .nice-select {
        @include breakpoint(max-sm) {
          margin-bottom: 30px;
        }
      }

      textarea {
        @extend input;
        resize: none;
        height: 130px;
      }

      button {
        padding: 15px;
        width: 100%;
        text-align: center;
        background-color: var(--primary-color);
        color: var(--white);
        font-weight: 700;
        border-radius: 10px;
        letter-spacing: 0.5px;
        transition: var(--transition);

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

// Subscribe area end here ***

// Introduction area start here ***
.introduction {
  &-area {
    position: relative;
    overflow: hidden;
  }

  &__shape {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__item {
    max-width: 630px;
    @include breakpoint(max-lg) {
      padding: 30px;
    }

    ul {
      max-width: 400px;
      display: flex;
      align-items: center;
      gap: 30px;

      li {
        display: flex;
        align-items: center;
        gap: 30px;
      }
    }
  }
}

// Introduction area end here ***

// Experience area start here ***
.experience {
  &__item {
    .item {
      border-radius: 10px;
      padding: 50px 10px;
      text-align: center;

      h2 {
        color: var(--white);

        span {
          color: var(--white);
          font-weight: 700;
        }
      }

      span {
        color: var(--white);
        font-weight: 600;
      }
    }
  }

  &__left-item {
    @include flex;
    gap: 30px;

    .left-item {
      background: linear-gradient(0deg, #2a2a30, #2a2a30),
      linear-gradient(0deg, #ffffff, #ffffff);

      width: 172px;
      border-radius: 10px;
      padding: 28px 40px;

      h2 {
        color: var(--primary-color);

        span {
          color: var(--primary-color);
          font-weight: 700;
        }
      }

      span {
        color: var(--white);
        font-weight: 600;
      }
    }

    .content {
      flex-basis: 60%;

      h4 {
        color: var(--white);
      }

      p {
        color: var(--white);
        opacity: 0.5;
      }
    }
  }
}

// Experience area end here ***

// Gallery area start here ***
.gallery {
  &__image {
    position: relative;

    .image-content {
      max-width: 350px;
      width: 100%;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      bottom: -85px;
      padding: 30px;
      border-radius: 10px;
      border-left: 4px solid var(--primary-color);
      background-color: var(--white);
      box-shadow: var(--shadow);

      h4 {
        margin-top: 5px;
      }
    }
  }

  &__item {
    .image99 {
      height: 99%;

      img {
        height: 100%;
      }

      @include breakpoint(max-xxl) {
        height: 100%;
      }
    }
  }
}

.gallery-two {
  &__image {
    @include breakpoint(max-md) {
      padding: 0 15px;
    }

    .image-content {
      opacity: 0;
      visibility: hidden;
      margin-bottom: -10px;
      transition: var(--transition);
      max-width: unset;
      width: 92%;
      bottom: 20px;
      border-left: none;
      background-color: var(--white);
      border-bottom: 3px solid var(--primary-color);

      h4 {
        margin-top: 0px;
      }
    }

    &:hover {
      .image-content {
        opacity: 1;
        visibility: visible;
        margin-bottom: 0px;
      }
    }
  }
}

// Gallery area end here ***

// Testimonial area start here ***
.testimonial {
  &__item {
    padding: 60px 30px;
    border-radius: 10px;
    border: 2px solid var(--primary-color);
    background-color: var(--black-sub);
    position: relative;

    .image {
      position: absolute;
      top: -50px;
      left: 30px;
      width: 100px;
    }

    .coma {
      position: absolute;
      top: 50px;
      right: 50px;

      i {
        font-size: 50px;
        color: var(--primary-color);
      }
    }

    p {
      font-size: 18px;
    }
  }

  &__content {
    padding-top: 20px;
  }
}

.testimonial-two {
  &__item {
    @extend .testimonial__item;
    padding: 50px 40px;
    padding-bottom: 40px;
    border: none;
    background-color: var(--white);
    box-shadow: var(--shadow);

    .coma {
      top: 60px;
      @include breakpoint(max-xxs) {
        top: 20px;
        right: 20px;
      }
    }

    p {
      font-size: 15px;
    }

    .user {
      margin-right: 20px;

      img {
        width: 90px;
      }
    }

    &.edited {
      border-radius: 0;
      box-shadow: unset;
    }
  }

  &__happy-user {
    background-color: var(--black-sub);
    border-radius: 10px;
    border-left: 2px solid var(--primary-color);
    padding: 30px;
    @include flex;
    gap: 20px;
    max-width: 285px;

    &.edited {
      background-color: var(--sub-bg);
      border: 2px solid var(--border);
    }
  }

  &__right-item {
    max-width: 570px;
    padding-left: 100px;
    @include breakpoint(max-xxl) {
      padding-left: 0;
    }
  }

  &__rating-review {
    background-color: var(--white);
    border-radius: 10px;
    border-bottom: 3px solid var(--primary-color);

    .rating-review-part {
      padding: 30px 40px;
    }
  }
}

// Testimonial area end here ***

// Blog area start here ***
.blog {
  &__image {
    overflow: hidden;
    position: relative;

    img {
      transition: var(--transition);
    }

    .date-tag {
      transition: var(--transition);
      text-align: center;
      position: absolute;
      bottom: 30px;
      left: 30px;
      padding: 10px 22px;
      border-radius: 10px;
      background-color: var(--white);

      h4 {
        line-height: 24px;
        transition: var(--transition);
      }

      span {
        font-size: 14px;
        transition: var(--transition);
      }
    }
  }

  &__content {
    padding: 0px 40px 40px 40px;

    ul {
      padding: 10px 0;
      border-bottom: var(--border-1px);
      @include flex;
      gap: 30px;

      li {
        a {
          color: var(--span);

          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }
  }

  &__item {
    box-shadow: var(--shadow);
    background-color: var(--white);
    border-radius: 10px;
    border-bottom: 3px solid transparent;
    overflow: hidden;

    &:hover {
      border-bottom: 3px solid var(--primary-color);

      .btn-three {
        border: 1px solid var(--primary-color);
      }

      .blog__image {
        .date-tag {
          background-color: var(--primary-color);

          h4,
          span {
            color: var(--white);
          }
        }

        img {
          transform: scale(1.04);
        }
      }
    }
  }

  &-details {
    &__left-item {
      .post-comment {
        .comment-item {
          @include flex;
          gap: 30px;
          padding: 40px;
          border-radius: 10px;
          background-color: var(--sub-bg);
          @include breakpoint(max-sm) {
            padding: 10px;
          }

          .image {
            width: 100%;
            max-width: 104px;
          }
        }

        .post-comment-form {
          @extend .subscribe__item;

          .form-check {
            @include flex-wrp;
            gap: 10px;

            p {
              @include breakpoint(max-sm) {
                font-size: 12px;
              }
            }

            .form-check-input {
              margin: 0;
              box-shadow: none;
              border-radius: 0px;
              padding: 6px 7px;
              border-radius: 2px;

              &:checked {
                background-color: var(--primary-color);
                border: 1px solid var(--primary-color);
              }
            }
          }
        }
      }

      .item {
        box-shadow: var(--shadow);
        border-radius: 10px;
        overflow: hidden;
      }

      .author-text {
        padding: 40px;

        h5 {
          border-bottom: 1px solid var(--primary-color);
          display: inline-block;
          padding-left: 10px;
          padding-right: 10px;
        }

        p {
          max-width: 690px;
          margin: 0 auto;
          padding: 10px 0;
        }
      }

      .content {
        padding: 40px;

        ul {
          @include flex;
          gap: 30px;

          li {
            @include flex;
            gap: 7px;
          }
        }

        @include breakpoint(max-sm) {
          padding: 20px;
          ul {
            gap: 10px;

            li {
              gap: 5px;

              span {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    &__right-item {
      .item {
        padding: 40px;
        border-radius: 10px;
        box-shadow: var(--shadow);

        .title {
          border-bottom: var(--border-1px);
          margin-bottom: 30px;

          h4 {
            border-bottom: 1px solid var(--primary-color);
            display: inline-block;
            margin-bottom: -1px;
            padding-bottom: 5px;
            text-transform: capitalize;
          }
        }

        .post-search {
          position: relative;

          input {
            width: 100%;
            padding: 10px 20px;
            border-radius: 10px;
            background-color: var(--sub-bg);
          }

          button {
            color: var(--white);
            padding: 10px 20px;
            background-color: var(--primary-color);
            border-radius: 10px;
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        .category {
          li {
            a {
              padding: 10px 20px;
              border-radius: 10px;
              background-color: var(--sub-bg);
              display: block;
              @include justify;

              span {
                color: var(--paragraph);

                i {
                  color: var(--secondary-color);
                  margin-right: 5px;
                  transition: var(--transition);
                }
              }

              &:hover {
                span {
                  i {
                    color: var(--primary-color);
                  }
                }
              }
            }
          }
        }

        .recent-post {
          li {
            @include flex;
            gap: 10px;

            .image {
              max-width: 110px;
              width: 100%;
              position: relative;
              border-radius: 10px;
              overflow: hidden;

              &::after {
                position: absolute;
                content: "";
                background-color: var(--primary-color);
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                opacity: 0;
                transition: var(--transition);
              }

              i {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: var(--white);
                z-index: 1;
                transition: var(--transition);
                opacity: 0;
                margin-left: -10px;
                visibility: hidden;
              }
            }

            .con {
              h5 {
                margin-bottom: 5px;
              }
            }

            &:hover {
              .image {
                i {
                  opacity: 1;
                  margin-left: 0;
                  visibility: visible;
                }

                &::after {
                  opacity: 0.7;
                }
              }
            }
          }
        }

        .tags {
          @include flex-wrp;
          gap: 20px;

          a {
            padding: 5px 20px;
            border-radius: 5px;
            border: var(--border-1px);
            color: var(--span);
            font-size: 14px;

            &:hover {
              color: var(--primary-color);
            }
          }
        }
      }
    }
  }
}

.blog-two {
  &__content {
    flex-basis: 52%;
  }

  &__item {
    padding: 30px;
    border-radius: 10px;
    box-shadow: var(--shadow);

    .btn-three {
      border: 1px solid var(--primary-color);
    }

    @include flex;
    gap: 30px;
    @include breakpoint(max-xs) {
      flex-direction: column;
    }

    .image {
      overflow: hidden;
      border-radius: 10px;
      max-width: 270px;
      position: relative;

      img {
        transition: var(--transition);
      }

      .blog-tag {
        position: absolute;
        top: 0;
        left: 0;
        padding: 5px 10px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: var(--primary-color);
        color: var(--white);
      }
    }

    &:hover {
      img {
        transform: scale(1.04);
      }
    }
  }
}

// Blog area end here ***

// Join us area start here ***
.join-us {
  &-area {
    margin-bottom: -142px;
    position: relative;
    z-index: 1;
  }

  &__item {
    padding: 80px 60px;
    background-color: var(--primary-color);
    border-radius: 10px;
    @include breakpoint(max-sm) {
      padding: 30px;
    }
  }

  &__input {
    position: relative;

    input {
      width: 100%;
      padding: 20px 22px;
      border-radius: 10px;
    }

    button {
      padding: 15px 30px;
      color: var(--white);
      font-weight: 700;
      background-color: var(--secondary-color);
      border-radius: 10px;
      position: absolute;
      right: 5px;
      top: 5px;
      transition: var(--transition);

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

// Join us area end here ***

// Talk us area start here ***
.talk-us {
  &__container {
    max-width: 1274px;
  }

  &__left {
    max-width: 560px;
  }

  &__now {
    background-color: var(--sub-bg);
    box-shadow: none;
  }

  &__item {
    @include flex;
    gap: 20px;

    h2 {
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: var(--black);
      color: transparent;

      span {
        color: transparent;
      }
    }

    @include breakpoint(max-sm) {
      .icon {
        width: 40px;

        img {
          width: 100%;
        }
      }
      .content {
        p {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }
}

// Talk us area end here ***

// Process area start here ***
.process {
  &__content {
    padding: 30px;
    position: relative;

    h3 {
      position: absolute;
      top: 25px;
      right: 20px;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: var(--span);
      color: transparent;
    }
  }

  &__item {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: var(--shadow);
    background-color: var(--white);
  }

  &-two {
    position: relative;
    overflow: hidden;

    &__shape {
      position: absolute;
      top: -50px;
      left: 15%;
      z-index: -1;
    }

    &__item {
      position: relative;
      overflow: hidden;
      background-color: var(--white);

      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 2px;
        background-color: var(--primary-color);
        content: "";
        transition: var(--transition);
      }

      .number {
        font-size: 26px;
        font-weight: 700;
        display: inline-block;
        color: var(--heading-color);
        opacity: 10%;
        position: absolute;
        right: 40px;
        top: 50px;

        &::after {
          position: absolute;
          content: "";
          left: -55px;
          top: 15px;
          width: 45px;
          height: 1px;
          background-color: var(--heading-color);
        }
      }

      border: var(--border-1px);
      border-radius: 5px;
      transition: var(--transition);
      padding: 40px;

      .icon {
        @include radius(85px, 85px, 85px);
        border: 1px dashed var(--border);
        border-radius: 5px;
        transition: var(--transition);
      }

      &:hover {
        .icon {
          border: 1px dashed var(--primary-color);
        }

        box-shadow: var(--shadow);
        border: 1px solid transparent;

        &::after {
          width: 100%;
          left: 0;
        }
      }
    }
  }
}

// Process area end here ***

// Brand area start here ***
.brand-two {
  &-area {
    padding-top: 250px;
    margin-top: -156px;
  }
}

// Brand area end here ***

// Faq area start here ***
.faq {
  &__item {
    max-width: 650px;
    padding-left: 40px;
    padding-top: 60px;
    @include breakpoint(max-xxl) {
      padding-left: 0;
    }
    @include breakpoint(max-lg) {
      padding: 0 30px;
    }
  }

  &-two {
    &__item {
      .accordion {
        .accordion-item {
          border: none;
          border-radius: 10px;
          margin-bottom: 30px;
          overflow: hidden;

          h2 {
            button {
              font-size: 15px;
              line-height: 28px;
              font-weight: 700;
              box-shadow: none;
              border-radius: 0 !important;
              padding: 20px 30px;
            }
          }

          .accordion-body {
            padding: 30px;
            background-color: var(--sub-bg);
          }
        }

        .accordion-button {
          background-color: var(--primary-color);
          color: var(--white);

          &::before {
            position: absolute;
            content: "\f068";
            font-family: "Font Awesome 6 Pro";
            font-weight: 700;
            top: 20px;
            right: 25px;
            font-size: 15px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 10px;
            color: var(--primary-color);
            background-color: var(--white);
            text-align: center;
            transition: var(--transition);
          }
        }

        .accordion-button.collapsed {
          background-color: var(--sub-bg);
          color: var(--heading-color);
          border-radius: 10px;

          &::before {
            content: "\2b";
            background-color: var(--sub-bg);
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
          }
        }
      }
    }

    &__search {
      max-width: 850px;
      margin: 0 auto;
      margin-bottom: 40px;

      input {
        padding: 10px 30px;
        width: 100%;
        border-radius: 10px;
        border: var(--border-1px);
      }

      position: relative;

      button {
        position: absolute;
        right: 30px;
        top: 11px;
        color: var(--paragraph);
      }
    }
  }
}

// Faq area end here ***

// Project details area start here ***
.project {
  &-details {
    &__item {
      ul {
        //max-width: 400px;
      }
    }
  }
}

// Project details area end here ***

// Contact area start here ***
.contact {
  &__form {
    background-color: var(--sub-bg);
    box-shadow: unset;

    h5 {
      border-bottom: 1px solid var(--primary-color);
      display: inline-block;
    }
  }

  &__content {
    .icon {
      @include radius(60px, 60px, 60px);
      border-radius: 10px;
      background-color: var(--primary-color);
    }

    @include breakpoint(max-sm) {
      border-right: none;
    }
  }

  &__map {
    iframe {
      width: 100%;
      height: 285px;
      border-radius: 10px;
    }
  }
}

// Contact area end here ***

// Error area start here ***
.error {
  text-align: center;
  overflow: hidden;

  h1 {
    font-size: 300px;
    color: var(--secondary-color);
    font-weight: 500;
    line-height: 250px;
    @include breakpoint(max-lg) {
      font-size: 100px;
      font-weight: 700;
      line-height: 100px;
    }
  }
}

// Error area end here ***
