.c {
  &__item {
    margin: 10px 0;
  }

  &__title {
    font-size: 18px;
  }

  &__description {
    padding-left: 20px;
  }
}