/*-----------------------------------------------------------------

Template Name:  Sunbee - Solar & Renewable Energy HTML Template
Author:  Elegant-Studio
Author URI: https://themeforest.net/user/elegant-studio/portfolio
Developer: Kawser Ahmed Roni
Version: 1.0.0
Description: Sunbee - Solar & Renewable Energy HTML Template

-------------------------------------------------------------------
CSS TABLE OF CONTENTS
-------------------------------------------------------------------

01. abstracts
    1.01 --> mixins
    1.02 --> variable

02. base
    2.01 --> typography
    2.02 --> animation
    2.03 --> responsive

03. components
    3.01 --> buttons
    3.02 --> progress

04. layout
    4.01 --> header
    4.02 --> banner
    4.03 --> section
    4.04 --> footer

------------------------------------------------------------------*/

// Google fonts ***
@import url("https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Abstracts styles ***
@import "_abstracts/mixins";
@import "_abstracts/variables";

// Typography styles***
@import "_base/typography";

// Component styles ***
@import "_components/buttons";
@import "_components/progress";

// Layout styles ***
@import "_layout/header";
@import "_layout/banner";
@import "_layout/section";
@import "_layout/footer";

// Animation styles***
@import "_base/animation";

body {
  font-size: 16px;
}

table {
  width: 100%;
  text-align: center;

  td {
    border: 1px solid #DDD;
    padding: 10px;
    text-align: left;
  }

  tr.highlighted {
    td {
      background: var(--primary-color);
      color: var(--bs-white);

      * {
        color: var(--bs-white);
      }
    }
  }

  tr.highlighted-secondary {
    td {
      background: var(--bs-gray);
      color: var(--bs-white);

      * {
        color: var(--bs-white);
      }
    }
  }

  table {
    tr {
      td {
        border: 0;
        border-bottom: 1px solid #DDD;
        border-right: 1px solid #DDD;

        &:last-child {
          border-right: 0;

        }
      }

      &:last-child {
        td {
          border-bottom: 0;
          border-right: 0;

        }
      }
    }
  }
}

.gs-table {
  & > tbody > tr:nth-child(even) > td {
    background-color: #f9f9f9;
  }
}