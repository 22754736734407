// Logo area start here ***
.logo {
  width: 100px;
  display: block;
  @media (max-width: 992px) {
    width: 60px;
  }

  img {
    width: 100%;
  }
}

// Logo area end here ***

// Top header area start here ***
.header-top {
  padding: 16px 0;
  overflow: hidden;
  background-color: var(--heading-color);

  .header-top-wrp {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .info {
      color: var(--white);

      span {
        color: var(--white);
      }

      a {
        transition: var(--transition);
        color: var(--white);
        font-size: 15px;

        &:hover {
          color: var(--primary-color);
        }
      }

      i {
        color: var(--primary-color);
        padding-right: 5px;
      }

      li {
        float: left;
        line-height: 0;
      }
    }

    .link-info {
      position: relative;
      z-index: 2;

      li {
        float: left;
        line-height: 0;

        a {
          width: 40px;
          color: var(--white);
          text-align: center;

          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }
  }
}

// Top header area end here ***

// Header area start here ***
.header {
  &-area {
    &.menu-fixed {
      position: fixed;
      width: 100%;
      top: 0;
      background-color: var(--white);
      z-index: 999;
      box-shadow: var(--shadow);

      .logo {
        width: 70px;
      }

      .main-menu ul li a {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
      }

      .sub-menu {
        top: 65px !important;
      }
    }
  }

  &__main {
    @include justify-wrp;
    justify-content: flex-start;
    @media (max-width: 992px) {
      justify-content: space-between;
    }
    gap: 40px;

    .main-menu {
      ul {
        @include flex;
        gap: 40px;

        li {
          position: relative;

          a {
            font-weight: 600;
            padding: 30px 0;

            i {
              font-size: 12px;
              transition: var(--transition);
            }
          }

          .sub-menu {
            position: absolute;
            left: 0;
            top: 80px;
            z-index: 99;
            flex-direction: column;
            gap: 0;
            width: 250px;
            border: var(--border-1px);
            box-shadow: var(--shadow);
            background-color: var(--white);
            border-radius: 10px;
            transition: var(--transition);
            opacity: 0;
            visibility: hidden;
            transform: translateY(10px);

            ul {
              top: 0;
              left: 100%;
              display: none;
            }

            li {
              width: 100%;

              &:not(:last-child) {
                border-bottom: 1px solid var(--border);
              }

              &:hover {
                ul {
                  display: block;
                }
              }

              a {
                display: block;
                padding: 12px 20px;
                color: var(--heading-color) !important;

                &:hover {
                  padding-left: 25px;
                  color: var(--primary-color) !important;
                }
              }
            }
          }

          &:hover {
            .sub-menu {
              opacity: 1;
              visibility: visible;
              transform: translateY(0px);
            }

            &:hover {
              a {
                color: var(--primary-color);
              }

              i {
                transform: rotate(-180deg);
                color: var(--primary-color);
              }
            }
          }
        }
      }
    }

    @include breakpoint(max-lg) {
      padding: 5px 0;
    }

    .bars {
      i {
        font-size: 22px;
        cursor: pointer;
        display: inline-block;
        color: var(--paragraph);
        padding: 10px;
        border-radius: 10px;
      }
    }
  }
}

// Header area end here ***

// Sidebar area start here ***
.sidebar-area {
  position: fixed;
  top: 0;
  right: 0px;
  width: 400px;
  height: 100%;
  background-color: var(--heading-color);
  padding: 40px;
  padding-top: 30px;
  z-index: 9999;
  transition: var(--transition);
  overflow: hidden;
  overflow-y: auto;
  @include breakpoint(max-sm) {
    width: 350px;
  }

  p {
    color: var(--white);
  }

  .info {
    border-top: 1px solid rgba(255, 255, 255, 0.25);

    li {
      font-size: 15px;

      i {
        margin-right: 8px;
      }

      a {
        color: var(--white);
      }
    }
  }

  .sidebar__overlay {
    position: fixed;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  button {
    position: absolute;
    right: 40px;
    top: 30px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    background-color: var(--primary-color);
    border-radius: 50%;
    transition: var(--transition);

    &:hover {
      transform: rotate(90deg);
    }
  }

  .social-icon {
    a {
      @include radius(40px, 40px, 40px);
      border: 1px solid rgba(255, 255, 255, 0.25);
      color: var(--primary-color);
      margin-right: 10px;

      &:hover {
        background-color: var(--primary-color);
        color: var(--heading-color);
        border: 1px solid var(--primary-color);
      }
    }
  }

  &.sidebar__hide {
    visibility: hidden;
    opacity: 0;
    right: -30px;
  }
}

// Sidebar area end here ***

// Mobile menu area start here ***
.mobile-menu {
  .meanmenu-reveal {
    display: none !important;
  }

  &.mean-container .mean-nav > ul {
    width: 100%;
    display: block !important;
  }

  ul {
    li {
      a {
        i {
          display: none;
        }
      }
    }
  }
}

// Mobile menu area end here ***
