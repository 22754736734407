/*-----------------------------------------------------------------

Template Name:  Sunbee - Solar & Renewable Energy HTML Template
Author:  Elegant-Studio
Author URI: https://themeforest.net/user/elegant-studio/portfolio
Developer: Kawser Ahmed Roni
Version: 1.0.0
Description: Sunbee - Solar & Renewable Energy HTML Template

-------------------------------------------------------------------
CSS TABLE OF CONTENTS
-------------------------------------------------------------------

01. abstracts
    1.01 --> mixins
    1.02 --> variable

02. base
    2.01 --> typography
    2.02 --> animation
    2.03 --> responsive

03. components
    3.01 --> buttons
    3.02 --> progress

04. layout
    4.01 --> header
    4.02 --> banner
    4.03 --> section
    4.04 --> footer

------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --exo: "Exo", sans-serif;
  --inter: "Inter", sans-serif;
  --primary-color: #4aab3d;
  --primary-10: rgba(73, 169, 61, 0.1);
  --secondary-color: #1f1f25;
  --sub-bg: #f6f7f9;
  --black-sub: #2a2a30;
  --heading-color: #1f1f25;
  --paragraph: #4f5359;
  --span: #6e777d;
  --border: #e9e9e9;
  --white: #ffffff;
  --black: #1f1f25;
  --border-1px: 1px solid #cccccc;
  --border-2px: 2px solid #e9e9e9;
  --transition: all 0.4s ease-in-out;
  --shadow: 0px 0px 60px 0px #3a3a3a8f;
  --shadow-xs: 0px 0px 15px 0px rgba(194, 194, 194, 0.26);
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--inter);
  color: var(--paragraph);
  background-color: var(--white);
  line-height: 28px;
  font-weight: 400;
  font-size: 15px;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: var(--heading-color);
  font-family: var(--exo);
}

h1 {
  font-size: 50px;
  font-weight: 700;
  line-height: 70px;
}
@media (max-width: 991px) {
  h1 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 30px;
    line-height: 40px;
  }
}

h2 {
  font-size: 42px;
  font-weight: 700;
  line-height: 52px;
}
@media (max-width: 767px) {
  h2 {
    font-size: 30px;
    line-height: 40px;
  }
}

h3 {
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
}

h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}

h6 {
  font-size: 14px;
  font-weight: 600;
}

p {
  margin: 0;
  padding: 0;
  line-height: 28px;
  font-size: 15px;
}

span {
  display: inline-block;
  color: var(--span);
}

a {
  text-decoration: none;
  display: inline-block;
  color: var(--heading-color);
  transition: var(--transition);
}

a:hover {
  color: var(--heading-color);
}

ul {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

li {
  list-style: none;
}

button {
  border: none;
  background-color: transparent;
}

input, .subscribe__item form textarea, .blog-details__left-item .post-comment .post-comment-form form textarea {
  border: none;
  outline: none;
}

@media (max-width: 991px) {
  br {
    display: none;
  }
}
::selection {
  color: var(--white);
  background-color: var(--primary-color);
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

/*-- Margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-155 {
  margin-left: 155px;
}

.ml-160 {
  margin-left: 160px;
}

.ml-165 {
  margin-left: 165px;
}

.ml-170 {
  margin-left: 170px;
}

.ml-175 {
  margin-left: 175px;
}

.ml-180 {
  margin-left: 180px;
}

.ml-185 {
  margin-left: 185px;
}

.ml-190 {
  margin-left: 190px;
}

.ml-195 {
  margin-left: 195px;
}

.ml-200 {
  margin-left: 200px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-105 {
  margin-right: 105px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-135 {
  margin-right: 135px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-145 {
  margin-right: 145px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-155 {
  margin-right: 155px;
}

.mr-160 {
  margin-right: 160px;
}

.mr-165 {
  margin-right: 165px;
}

.mr-170 {
  margin-right: 170px;
}

.mr-175 {
  margin-right: 175px;
}

.mr-180 {
  margin-right: 180px;
}

.mr-185 {
  margin-right: 185px;
}

.mr-190 {
  margin-right: 190px;
}

.mr-195 {
  margin-right: 195px;
}

.mr-200 {
  margin-right: 200px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-85 {
  padding-left: 85px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-95 {
  padding-left: 95px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-105 {
  padding-left: 105px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-135 {
  padding-left: 135px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-145 {
  padding-left: 145px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-160 {
  padding-left: 160px;
}

.pl-165 {
  padding-left: 165px;
}

.pl-170 {
  padding-left: 170px;
}

.pl-175 {
  padding-left: 175px;
}

.pl-180 {
  padding-left: 180px;
}

.pl-185 {
  padding-left: 185px;
}

.pl-190 {
  padding-left: 190px;
}

.pl-195 {
  padding-left: 195px;
}

.pl-200 {
  padding-left: 200px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-85 {
  padding-right: 85px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-95 {
  padding-right: 95px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-105 {
  padding-right: 105px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-135 {
  padding-right: 135px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-145 {
  padding-right: 145px;
}

.pr-150 {
  padding-right: 150px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-160 {
  padding-right: 160px;
}

.pr-165 {
  padding-right: 165px;
}

.pr-170 {
  padding-right: 170px;
}

.pr-175 {
  padding-right: 175px;
}

.pr-180 {
  padding-right: 180px;
}

.pr-185 {
  padding-right: 185px;
}

.pr-190 {
  padding-right: 190px;
}

.pr-195 {
  padding-right: 195px;
}

.pr-200 {
  padding-right: 200px;
}

@media (max-width: 767px) {
  .pt-120 {
    padding-top: 60px;
  }
  .pb-120 {
    padding-bottom: 60px;
  }
}
.bor {
  border: 1px solid var(--border);
}

.bor-top {
  border-top: 1px solid var(--border);
}

.bor-left {
  border-left: 1px solid var(--border);
}

.bor-bottom {
  border-bottom: 1px solid var(--border);
}

.bor-right {
  border-right: 1px solid var(--border);
}

.border-none {
  border: none !important;
}

.text-justify {
  text-align: justify;
}

.image.--shadow {
  box-shadow: var(--shadow-xs);
  padding: 10px;
  border-radius: 10px;
}
.image.--small {
  width: 70%;
}
@media (max-width: 767px) {
  .image.--small {
    width: 100%;
  }
}
.image img {
  width: 100%;
  border-radius: 10px;
}
.image-wrapper {
  display: flex;
  justify-content: center;
}

.primary-color {
  color: var(--primary-color);
}

.primary-hover:hover {
  color: var(--primary-color) !important;
}

.primary-bg {
  background-color: var(--primary-color);
}

.secondary-color {
  color: var(--secondary-color);
}

.secondary-bg {
  background-color: var(--secondary-color);
}

.sub-bg {
  background-color: var(--sub-bg);
}

.black-sub {
  background-color: var(--black-sub);
}

.black-para {
  color: var(--white);
  opacity: 0.6;
}

.sm-font {
  font-size: 14px;
}

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.radius10 {
  border-radius: 10px;
}

.transition {
  transition: var(--transition);
}

.overlay, .team__image, .banner-area {
  position: relative;
}
.overlay::before, .team__image::before, .banner-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.nice-select {
  width: 100%;
  border-radius: 10px;
  height: 50px;
  line-height: 50px;
}
.nice-select:focus {
  border: 1px solid var(--primary-color);
}
.nice-select .list {
  width: 100%;
}

.star i {
  color: var(--primary-color);
}

.pegi {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.pegi a {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  background-color: var(--primary-10);
  color: var(--heading-color);
  font-weight: 700;
}
.pegi a:hover {
  background-color: var(--primary-color);
  color: var(--white);
}
.pegi a:hover i {
  color: var(--white) !important;
}
.pegi a.active {
  background-color: var(--primary-color);
  color: var(--white);
}

#loading {
  background-color: var(--white);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999999;
  margin-top: 0px;
  top: 0px;
}
#loading #loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}
#loading #loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 25%;
  transform: translate(-50%, -50%);
}
#loading .loading-icon .loading-logo {
  width: 150px;
  height: 150px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  #loading #loading-center-absolute {
    width: 40%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #loading #loading-center-absolute {
    width: 40%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #loading #loading-center-absolute {
    width: 45%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  #loading #loading-center-absolute {
    width: 50%;
  }
}
@media (max-width: 575px) {
  #loading #loading-center-absolute {
    width: 60%;
  }
}

.display-6 {
  font-size: 1.4rem !important;
}

.btn-one, .btn-three, .btn-two {
  padding: 10px 30px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 700;
  color: var(--white);
  background-color: var(--primary-color);
  display: inline-block;
  position: relative;
  letter-spacing: 0.5px;
  z-index: 3;
  overflow: hidden;
  text-transform: capitalize;
}
@media (max-width: 450px) {
  .btn-one, .btn-three, .btn-two {
    padding: 8px 16px;
    font-size: 14px;
  }
}
.btn-one::before, .btn-three::before, .btn-two::before {
  content: "";
  position: absolute;
  height: 400px;
  width: 430px;
  top: 50%;
  left: 50%;
  background: var(--white);
  border-radius: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0);
  transition: all 0.4s ease-out 0s;
  z-index: -1;
}
.btn-one:hover, .btn-three:hover, .btn-two:hover {
  color: var(--primary-color);
}
.btn-one:hover::before, .btn-three:hover::before, .btn-two:hover::before {
  transform: translateY(-50%) translateX(-50%) scale(1);
}

.btn-right {
  margin-left: auto;
}

.btn-two::before {
  background: var(--black);
}
.btn-two:hover {
  color: var(--white);
}

.btn-three {
  color: var(--black);
  border: var(--border-1px);
  background-color: transparent;
}
.btn-three::before {
  background: var(--primary-color);
}
.btn-three:hover {
  color: var(--white);
}

.more-btn {
  position: relative;
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
  letter-spacing: 0.5px;
}
.more-btn i {
  color: var(--primary-color);
}
.more-btn::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--primary-color);
  left: 0;
  bottom: 0;
  transition: var(--transition);
}
.more-btn:hover::after {
  width: 78%;
}

.arry-prev, .arry-next {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  background-color: rgba(73, 169, 61, 0.1);
  color: var(--primary-color);
  transition: var(--transition);
}
.arry-prev:hover, .arry-next:hover {
  background-color: var(--primary-color);
  color: var(--white);
}
.arry-prev.active, .active.arry-next {
  background-color: var(--primary-color);
  color: var(--white);
}

.dot .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.6s;
  background-color: transparent;
  opacity: 1;
  position: relative;
  border: 1px solid transparent;
}
.dot .swiper-pagination-bullet::before {
  position: absolute;
  content: "";
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--primary-color);
  transition: 0.6s;
}
.dot .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: 1px solid var(--primary-color);
}
.dot .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
  background-color: var(--primary-color);
}

.video-btn {
  position: relative;
  text-align: center;
  display: inline-block;
  z-index: 2;
}
.video-btn a {
  position: relative;
  color: var(--white);
  font-size: 28px;
  z-index: 1;
  background-color: var(--primary-color);
  width: 90px;
  height: 90px;
  line-height: 90px;
  border-radius: 50%;
  display: block;
  transition: 0.4s;
}

.video-pulse::after,
.video-pulse::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid var(--primary-color);
  opacity: 0.7;
  left: 0;
  top: 0;
  border-radius: 50%;
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: video-animation;
  animation-name: video-animation;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.video-pulse::before {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.scroll-up {
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px var(--border);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  position: fixed;
  right: 25px;
  bottom: 35px;
  height: 50px;
  width: 50px;
  transition: var(--transition);
}
.scroll-up::after {
  position: absolute;
  font-family: "Font Awesome 6 Pro";
  content: "\f30c";
  text-align: center;
  line-height: 50px;
  font-weight: 700;
  font-size: 18px;
  color: var(--primary-color);
  left: 0;
  top: 0;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: block;
  z-index: 1;
  transition: var(--transition);
}
.scroll-up svg path {
  fill: none;
}
.scroll-up svg.scroll-circle path {
  stroke: var(--primary-color);
  stroke-width: 4px;
  box-sizing: border-box;
  transition: var(--transition);
}
.scroll-up.active-scroll {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.logo {
  width: 100px;
  display: block;
}
@media (max-width: 992px) {
  .logo {
    width: 60px;
  }
}
.logo img {
  width: 100%;
}

.header-top {
  padding: 16px 0;
  overflow: hidden;
  background-color: var(--heading-color);
}
.header-top .header-top-wrp {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-top .header-top-wrp .info {
  color: var(--white);
}
.header-top .header-top-wrp .info span {
  color: var(--white);
}
.header-top .header-top-wrp .info a {
  transition: var(--transition);
  color: var(--white);
  font-size: 15px;
}
.header-top .header-top-wrp .info a:hover {
  color: var(--primary-color);
}
.header-top .header-top-wrp .info i {
  color: var(--primary-color);
  padding-right: 5px;
}
.header-top .header-top-wrp .info li {
  float: left;
  line-height: 0;
}
.header-top .header-top-wrp .link-info {
  position: relative;
  z-index: 2;
}
.header-top .header-top-wrp .link-info li {
  float: left;
  line-height: 0;
}
.header-top .header-top-wrp .link-info li a {
  width: 40px;
  color: var(--white);
  text-align: center;
}
.header-top .header-top-wrp .link-info li a:hover {
  color: var(--primary-color);
}

.header-area.menu-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: var(--white);
  z-index: 999;
  box-shadow: var(--shadow);
}
.header-area.menu-fixed .logo {
  width: 70px;
}
.header-area.menu-fixed .main-menu ul li a {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.header-area.menu-fixed .sub-menu {
  top: 65px !important;
}
.header__main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  justify-content: flex-start;
  gap: 40px;
}
@media (max-width: 992px) {
  .header__main {
    justify-content: space-between;
  }
}
.header__main .main-menu ul {
  display: flex;
  align-items: center;
  gap: 40px;
}
.header__main .main-menu ul li {
  position: relative;
}
.header__main .main-menu ul li a {
  font-weight: 600;
  padding: 30px 0;
}
.header__main .main-menu ul li a i {
  font-size: 12px;
  transition: var(--transition);
}
.header__main .main-menu ul li .sub-menu {
  position: absolute;
  left: 0;
  top: 80px;
  z-index: 99;
  flex-direction: column;
  gap: 0;
  width: 250px;
  border: var(--border-1px);
  box-shadow: var(--shadow);
  background-color: var(--white);
  border-radius: 10px;
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
}
.header__main .main-menu ul li .sub-menu ul {
  top: 0;
  left: 100%;
  display: none;
}
.header__main .main-menu ul li .sub-menu li {
  width: 100%;
}
.header__main .main-menu ul li .sub-menu li:not(:last-child) {
  border-bottom: 1px solid var(--border);
}
.header__main .main-menu ul li .sub-menu li:hover ul {
  display: block;
}
.header__main .main-menu ul li .sub-menu li a {
  display: block;
  padding: 12px 20px;
  color: var(--heading-color) !important;
}
.header__main .main-menu ul li .sub-menu li a:hover {
  padding-left: 25px;
  color: var(--primary-color) !important;
}
.header__main .main-menu ul li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}
.header__main .main-menu ul li:hover:hover a {
  color: var(--primary-color);
}
.header__main .main-menu ul li:hover:hover i {
  transform: rotate(-180deg);
  color: var(--primary-color);
}
@media (max-width: 991px) {
  .header__main {
    padding: 5px 0;
  }
}
.header__main .bars i {
  font-size: 22px;
  cursor: pointer;
  display: inline-block;
  color: var(--paragraph);
  padding: 10px;
  border-radius: 10px;
}

.sidebar-area {
  position: fixed;
  top: 0;
  right: 0px;
  width: 400px;
  height: 100%;
  background-color: var(--heading-color);
  padding: 40px;
  padding-top: 30px;
  z-index: 9999;
  transition: var(--transition);
  overflow: hidden;
  overflow-y: auto;
}
@media (max-width: 575px) {
  .sidebar-area {
    width: 350px;
  }
}
.sidebar-area p {
  color: var(--white);
}
.sidebar-area .info {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}
.sidebar-area .info li {
  font-size: 15px;
}
.sidebar-area .info li i {
  margin-right: 8px;
}
.sidebar-area .info li a {
  color: var(--white);
}
.sidebar-area .sidebar__overlay {
  position: fixed;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: -1;
}
.sidebar-area button {
  position: absolute;
  right: 40px;
  top: 30px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  background-color: var(--primary-color);
  border-radius: 50%;
  transition: var(--transition);
}
.sidebar-area button:hover {
  transform: rotate(90deg);
}
.sidebar-area .social-icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: var(--primary-color);
  margin-right: 10px;
}
.sidebar-area .social-icon a:hover {
  background-color: var(--primary-color);
  color: var(--heading-color);
  border: 1px solid var(--primary-color);
}
.sidebar-area.sidebar__hide {
  visibility: hidden;
  opacity: 0;
  right: -30px;
}

.mobile-menu .meanmenu-reveal {
  display: none !important;
}
.mobile-menu.mean-container .mean-nav > ul {
  width: 100%;
  display: block !important;
}
.mobile-menu ul li a i {
  display: none;
}

.banner-area {
  padding-top: 100px;
  padding-bottom: 130px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 991px) {
  .banner-area {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.banner-area::before {
  background: linear-gradient(94.58deg, #1f1f25 0.34%, rgba(31, 31, 37, 0.79) 98.11%);
}
.banner__right {
  position: absolute;
  left: 50%;
  bottom: 0;
}
@media (max-width: 991px) {
  .banner__right {
    display: none;
  }
}
.banner__content {
  position: relative;
  max-width: 652px;
}
@media (max-width: 1199px) {
  .banner__content {
    max-width: 530px;
  }
}
@media (max-width: 991px) {
  .banner__content {
    max-width: 100%;
  }
}
.banner__content h1 {
  color: var(--white);
}
.banner__content p {
  color: var(--white);
  margin-top: 20px;
}

.banner-two-area {
  padding: 150px 0;
  overflow: hidden;
}
.banner-two-area::before {
  display: none;
}
.banner-two__shape {
  position: absolute;
  left: 0;
  bottom: 0;
}
.banner-two__content {
  margin-left: 10%;
  position: relative;
  max-width: 580px;
}
.banner-two__content h1 {
  color: var(--heading-color);
  font-size: 60px;
  line-height: 80px;
}
@media (max-width: 767px) {
  .banner-two__content h1 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 450px) {
  .banner-two__content h1 {
    font-size: 30px;
    line-height: 40px;
  }
}
.banner-two__content p {
  color: var(--paragraph);
  margin-top: 20px;
}

.page-banner {
  background-image: url(../../assets/images/banner/banner-fp.jpg);
  text-align: center;
}
.page-banner h2,
.page-banner a,
.page-banner span {
  color: var(--white);
}
.page-banner h2 {
  margin-bottom: 10px;
}
.page-banner a:hover {
  color: var(--primary-color);
}

.section-header h5 {
  color: var(--primary-color);
  padding-bottom: 5px;
  text-transform: uppercase;
  border-bottom: 1px solid var(--primary-color);
  display: inline-block;
  margin-bottom: 20px;
}
.section-header p {
  margin-top: 20px;
}

.accordion .accordion-item {
  border: var(--border-1px);
  border-radius: 10px;
  margin-bottom: 30px;
}
.accordion .accordion-item h2 button {
  font-size: 15px;
  line-height: 28px;
  font-weight: 700;
  box-shadow: none;
  border-radius: 0 !important;
  padding: 20px 30px;
}
.accordion .accordion-item .accordion-body {
  padding: 20px 30px;
  padding-top: 0;
}
.accordion .accordion-button {
  background-color: transparent;
  color: var(--heading-color);
}
.accordion .accordion-button::after {
  display: none;
}
.accordion .accordion-button::before {
  position: absolute;
  content: "\f068";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  top: 20px;
  right: 25px;
  font-size: 15px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 10px;
  color: var(--white);
  background-color: var(--primary-color);
  text-align: center;
  transition: var(--transition);
}
.accordion .accordion-button.collapsed {
  background-color: transparent;
  color: var(--heading-color);
}
.accordion .accordion-button.collapsed::before {
  content: "+";
  background-color: var(--sub-bg);
  color: var(--primary-color);
}

.service__content p {
  min-height: 105px;
}
@media (max-width: 991px) {
  .service__wrp {
    margin-top: 0px;
  }
}
.service__title {
  margin-bottom: 80px;
  padding-top: 80px;
}
@media (max-width: 991px) {
  .service__title {
    margin-bottom: 20px;
    padding-top: 40px;
  }
}
.service__icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  border-radius: 10px;
  background-color: var(--primary-color);
  position: absolute;
  left: 40px;
  bottom: 15px;
  transition: var(--transition);
}
.service__icon img {
  max-width: 45px;
  max-height: 45px;
}
.service__item {
  position: relative;
  background-color: var(--black-sub);
  border-radius: 10px;
  box-shadow: var(--shadow);
  padding: 40px;
  border: 1px solid transparent;
  transition: var(--transition);
}
.service__item svg {
  fill: #000 !important;
  width: 100px;
}
.service__item p {
  border-bottom: var(--border-1px);
}
.service__item:hover {
  border: 1px solid var(--primary-color);
}
.service__item:hover .service__icon {
  transform: translate(5px);
}
@media (max-width: 991px) {
  .service__item {
    margin-top: 30px;
  }
}
.service-details__image {
  position: relative;
}
.service-details__image .image-icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  border-radius: 10px;
  background-color: var(--primary-color);
  position: absolute;
  left: 20px;
  top: 20px;
}
.service-details__image .image-icon img {
  width: 45px;
}
.service-details__left-item .info {
  padding: 60px 40px;
  border-radius: 10px;
  box-shadow: var(--shadow);
}
.service-details__left-item .info .call-icon {
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  color: var(--white);
  background-color: var(--primary-color);
  margin-bottom: 10px;
}
.service-details__left-item .info span {
  color: var(--heading-color);
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 5px;
}
.service-details__left-item .left-title {
  position: relative;
}
.service-details__left-item .left-title::after {
  position: absolute;
  content: "";
  width: 70px;
  height: 3px;
  background-color: var(--heading-color);
  left: 0;
  bottom: -5px;
}
.service-details__left-item .service-list {
  border: none;
}
.service-details__left-item .service-list li {
  margin-top: 10px;
  width: 100%;
}
.service-details__left-item .service-list li a {
  padding: 15px 30px;
  color: var(--heading-color);
  background-color: var(--sub-bg);
  border-radius: 10px;
  font-weight: 600;
  display: block;
  border: none;
  text-transform: capitalize;
}
.service-details__left-item .service-list li a i {
  float: right;
  line-height: 30px;
  color: var(--paragraph);
  transition: var(--transition);
}
.service-details__left-item .service-list li a:hover {
  color: var(--white);
  background-color: var(--primary-color);
}
.service-details__left-item .service-list li a:hover i {
  color: var(--white);
  margin-right: -5px;
}
.service-details__left-item .service-list li a.active {
  color: var(--white);
  background-color: var(--primary-color);
}
.service-details__left-item .service-list li a.active i {
  color: var(--white);
  margin-right: -5px;
}
.service-details__right-item .subscribe__item h4 span, .service-details__right-item .blog-details__left-item .post-comment .post-comment-form h4 span, .blog-details__left-item .post-comment .service-details__right-item .post-comment-form h4 span {
  font-size: 15px;
  cursor: pointer;
}
.service-details__right-item .abmin .content::before {
  position: absolute;
  content: "";
  width: 30px;
  height: 30px;
  background-color: var(--white);
  border-left: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
  transform: rotate(45deg);
  top: 40%;
  left: -16px;
}
.service-details__right-item .nav-tabs {
  border: none;
  margin-bottom: 30px;
}
.service-details__right-item .nav-tabs .nav-link {
  margin-right: 30px;
  border: none;
  padding: 0;
  padding-bottom: 5px;
  font-weight: 600;
  color: var(--heading-color);
  box-shadow: none;
}
.service-details__right-item .nav-tabs .nav-link.active {
  border-bottom: 2px solid var(--primary-color);
}

.service-two {
  position: relative;
}
.service-two__bg {
  height: 550px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.service-two__item {
  position: relative;
  padding: 50px 40px;
  text-align: center;
  background-color: var(--white);
  box-shadow: var(--shadow);
  border-radius: 10px;
  border-bottom: 3px solid transparent;
}
.service-two__item.--equal {
  min-height: 285px;
}
.service-two__item .icon {
  width: 125px;
  height: 125px;
  line-height: 125px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  background-color: var(--sub-bg);
  margin: 0 auto;
  position: relative;
  transform: translateY(0px);
}
.service-two__item .icon::after {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  bottom: -20px;
  height: 50%;
  right: 33px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 70px 16px;
  z-index: 1;
  transform: rotate3d(1, 0, 0, 70deg);
  transition: var(--transition);
  opacity: 0;
}
.service-two__item.active {
  border-bottom: 3px solid var(--primary-color);
}
.service-two__item.active .icon {
  transform: translateY(-10px);
}
.service-two__item.active .icon::after {
  opacity: 1;
}
.service-two__item.active .btn-three {
  background: var(--primary-color);
  color: var(--white);
}
.service-two__item.page-two {
  border-bottom: 2px solid transparent;
}
.service-two__item.page-two .icon {
  background-color: var(--primary-10);
}
.service-two__item.page-two.active {
  border-bottom: 2px solid var(--primary-color);
}

.about-area {
  padding-bottom: 120px;
}
@media (max-width: 1399px) {
  .about-area {
    padding-bottom: 200px;
  }
}
@media (max-width: 767px) {
  .about-area {
    padding-bottom: 45px;
  }
}
.about__image {
  max-width: 571px;
  margin-top: 60px;
}
@media (max-width: 991px) {
  .about__image {
    margin-top: 0;
  }
}
.about__content {
  max-width: 560px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.about__content ul {
  margin-top: 30px;
}
.about__content ul li {
  line-height: 22px;
  font-weight: 600;
  color: var(--black);
  font-family: var(--exo);
}
.about__content ul li:not(:last-child) {
  margin-bottom: 10px;
}
.about__content ul li i {
  color: var(--primary-color);
  margin-right: 10px;
}

.choose-area {
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .choose-area {
    padding-bottom: 60px;
  }
}
.choose__item {
  padding-left: 80px;
}
@media (max-width: 1199px) {
  .choose__item {
    padding-left: 0;
  }
}
.choose__content .choose-info li {
  display: flex;
  align-items: center;
}
.choose__content .choose-info li .icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  box-shadow: var(--shadow);
  margin-right: 25px;
}
.choose__content .choose-info li p {
  flex-basis: 70%;
}

.video-area {
  margin-top: -13%;
}
.video__image {
  position: relative;
}
.video__btn-wrp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.team__image {
  overflow: hidden;
  border-radius: 10px;
}
.team__image img {
  transition: var(--transition);
}
.team__image .social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 25px;
}
.team__image .social-icons a {
  font-size: 14px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  background-color: var(--white);
  border-radius: 5px;
  color: var(--black);
  opacity: 0;
  visibility: hidden;
  transform: rotateY(-90deg);
}
.team__image .social-icons a:hover {
  background-color: var(--primary-color);
  color: var(--white);
}
.team__image .social-icons .active {
  background-color: var(--primary-color);
  color: var(--white);
}
.team__image::before {
  background: linear-gradient(180deg, rgba(31, 31, 37, 0) 33.33%, #1f1f25 100%);
  transition: var(--transition);
  opacity: 0;
}
.team__item h3 {
  font-weight: 700;
}
.team__item:hover .team__image .social-icons a {
  opacity: 1;
  visibility: visible;
  transform: rotateY(0deg);
}
.team__item:hover .team__image::before {
  opacity: 1;
}

.team-single__image {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}
.team-single__image img {
  width: 100%;
}
.team-single__image .team-info {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 20px;
  background-color: var(--primary-color);
  border-top-right-radius: 10px;
}
.team-single__image .team-info a {
  margin: 10px 0;
  color: var(--white);
}
.team-single__image .team-info a:hover {
  color: var(--heading-color);
}
.team-single__info .skills .experience-title {
  font-weight: 400;
}
.team-single__info .skills .progress {
  height: 4px;
}
.team-single__info .skills .progress .progress-bar {
  background-color: var(--primary-color);
}

.subscribe__item, .blog-details__left-item .post-comment .post-comment-form {
  background-color: var(--white);
  box-shadow: var(--shadow);
  border-radius: 10px;
  padding: 40px;
}
.subscribe__item h5, .blog-details__left-item .post-comment .post-comment-form h5 {
  color: var(--primary-color);
}
.subscribe__item h2, .blog-details__left-item .post-comment .post-comment-form h2 {
  margin-bottom: 20px;
}
.subscribe__item form input, .blog-details__left-item .post-comment .post-comment-form form input, .subscribe__item form textarea, .blog-details__left-item .post-comment .post-comment-form form textarea {
  width: 100%;
  padding: 11px 20px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  margin-bottom: 30px;
}
.subscribe__item form input:focus, .blog-details__left-item .post-comment .post-comment-form form input:focus, .subscribe__item form textarea:focus, .blog-details__left-item .post-comment .post-comment-form form textarea:focus {
  border: 1px solid var(--primary-color);
}
@media (max-width: 575px) {
  .subscribe__item form .nice-select, .blog-details__left-item .post-comment .post-comment-form form .nice-select {
    margin-bottom: 30px;
  }
}
.subscribe__item form textarea, .blog-details__left-item .post-comment .post-comment-form form textarea {
  resize: none;
  height: 130px;
}
.subscribe__item form button, .blog-details__left-item .post-comment .post-comment-form form button {
  padding: 15px;
  width: 100%;
  text-align: center;
  background-color: var(--primary-color);
  color: var(--white);
  font-weight: 700;
  border-radius: 10px;
  letter-spacing: 0.5px;
  transition: var(--transition);
}
.subscribe__item form button:hover, .blog-details__left-item .post-comment .post-comment-form form button:hover {
  opacity: 0.8;
}

.introduction-area {
  position: relative;
  overflow: hidden;
}
.introduction__shape {
  position: absolute;
  right: 0;
  bottom: 0;
}
.introduction__item {
  max-width: 630px;
}
@media (max-width: 991px) {
  .introduction__item {
    padding: 30px;
  }
}
.introduction__item ul {
  max-width: 400px;
  display: flex;
  align-items: center;
  gap: 30px;
}
.introduction__item ul li {
  display: flex;
  align-items: center;
  gap: 30px;
}

.experience__item .item {
  border-radius: 10px;
  padding: 50px 10px;
  text-align: center;
}
.experience__item .item h2 {
  color: var(--white);
}
.experience__item .item h2 span {
  color: var(--white);
  font-weight: 700;
}
.experience__item .item span {
  color: var(--white);
  font-weight: 600;
}
.experience__left-item {
  display: flex;
  align-items: center;
  gap: 30px;
}
.experience__left-item .left-item {
  background: linear-gradient(0deg, #2a2a30, #2a2a30), linear-gradient(0deg, #ffffff, #ffffff);
  width: 172px;
  border-radius: 10px;
  padding: 28px 40px;
}
.experience__left-item .left-item h2 {
  color: var(--primary-color);
}
.experience__left-item .left-item h2 span {
  color: var(--primary-color);
  font-weight: 700;
}
.experience__left-item .left-item span {
  color: var(--white);
  font-weight: 600;
}
.experience__left-item .content {
  flex-basis: 60%;
}
.experience__left-item .content h4 {
  color: var(--white);
}
.experience__left-item .content p {
  color: var(--white);
  opacity: 0.5;
}

.gallery__image {
  position: relative;
}
.gallery__image .image-content {
  max-width: 350px;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -85px;
  padding: 30px;
  border-radius: 10px;
  border-left: 4px solid var(--primary-color);
  background-color: var(--white);
  box-shadow: var(--shadow);
}
.gallery__image .image-content h4 {
  margin-top: 5px;
}
.gallery__item .image99 {
  height: 99%;
}
.gallery__item .image99 img {
  height: 100%;
}
@media (max-width: 1399px) {
  .gallery__item .image99 {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .gallery-two__image {
    padding: 0 15px;
  }
}
.gallery-two__image .image-content {
  opacity: 0;
  visibility: hidden;
  margin-bottom: -10px;
  transition: var(--transition);
  max-width: unset;
  width: 92%;
  bottom: 20px;
  border-left: none;
  background-color: var(--white);
  border-bottom: 3px solid var(--primary-color);
}
.gallery-two__image .image-content h4 {
  margin-top: 0px;
}
.gallery-two__image:hover .image-content {
  opacity: 1;
  visibility: visible;
  margin-bottom: 0px;
}

.testimonial__item, .testimonial-two__item {
  padding: 60px 30px;
  border-radius: 10px;
  border: 2px solid var(--primary-color);
  background-color: var(--black-sub);
  position: relative;
}
.testimonial__item .image, .testimonial-two__item .image {
  position: absolute;
  top: -50px;
  left: 30px;
  width: 100px;
}
.testimonial__item .coma, .testimonial-two__item .coma {
  position: absolute;
  top: 50px;
  right: 50px;
}
.testimonial__item .coma i, .testimonial-two__item .coma i {
  font-size: 50px;
  color: var(--primary-color);
}
.testimonial__item p, .testimonial-two__item p {
  font-size: 18px;
}
.testimonial__content {
  padding-top: 20px;
}

.testimonial-two__item {
  padding: 50px 40px;
  padding-bottom: 40px;
  border: none;
  background-color: var(--white);
  box-shadow: var(--shadow);
}
.testimonial-two__item .coma {
  top: 60px;
}
@media (max-width: 470px) {
  .testimonial-two__item .coma {
    top: 20px;
    right: 20px;
  }
}
.testimonial-two__item p {
  font-size: 15px;
}
.testimonial-two__item .user {
  margin-right: 20px;
}
.testimonial-two__item .user img {
  width: 90px;
}
.testimonial-two__item.edited {
  border-radius: 0;
  box-shadow: unset;
}
.testimonial-two__happy-user {
  background-color: var(--black-sub);
  border-radius: 10px;
  border-left: 2px solid var(--primary-color);
  padding: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 285px;
}
.testimonial-two__happy-user.edited {
  background-color: var(--sub-bg);
  border: 2px solid var(--border);
}
.testimonial-two__right-item {
  max-width: 570px;
  padding-left: 100px;
}
@media (max-width: 1399px) {
  .testimonial-two__right-item {
    padding-left: 0;
  }
}
.testimonial-two__rating-review {
  background-color: var(--white);
  border-radius: 10px;
  border-bottom: 3px solid var(--primary-color);
}
.testimonial-two__rating-review .rating-review-part {
  padding: 30px 40px;
}

.blog__image {
  overflow: hidden;
  position: relative;
}
.blog__image img {
  transition: var(--transition);
}
.blog__image .date-tag {
  transition: var(--transition);
  text-align: center;
  position: absolute;
  bottom: 30px;
  left: 30px;
  padding: 10px 22px;
  border-radius: 10px;
  background-color: var(--white);
}
.blog__image .date-tag h4 {
  line-height: 24px;
  transition: var(--transition);
}
.blog__image .date-tag span {
  font-size: 14px;
  transition: var(--transition);
}
.blog__content {
  padding: 0px 40px 40px 40px;
}
.blog__content ul {
  padding: 10px 0;
  border-bottom: var(--border-1px);
  display: flex;
  align-items: center;
  gap: 30px;
}
.blog__content ul li a {
  color: var(--span);
}
.blog__content ul li a:hover {
  color: var(--primary-color);
}
.blog__item {
  box-shadow: var(--shadow);
  background-color: var(--white);
  border-radius: 10px;
  border-bottom: 3px solid transparent;
  overflow: hidden;
}
.blog__item:hover {
  border-bottom: 3px solid var(--primary-color);
}
.blog__item:hover .btn-three {
  border: 1px solid var(--primary-color);
}
.blog__item:hover .blog__image .date-tag {
  background-color: var(--primary-color);
}
.blog__item:hover .blog__image .date-tag h4,
.blog__item:hover .blog__image .date-tag span {
  color: var(--white);
}
.blog__item:hover .blog__image img {
  transform: scale(1.04);
}
.blog-details__left-item .post-comment .comment-item {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 40px;
  border-radius: 10px;
  background-color: var(--sub-bg);
}
@media (max-width: 575px) {
  .blog-details__left-item .post-comment .comment-item {
    padding: 10px;
  }
}
.blog-details__left-item .post-comment .comment-item .image {
  width: 100%;
  max-width: 104px;
}
.blog-details__left-item .post-comment .post-comment-form .form-check {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
@media (max-width: 575px) {
  .blog-details__left-item .post-comment .post-comment-form .form-check p {
    font-size: 12px;
  }
}
.blog-details__left-item .post-comment .post-comment-form .form-check .form-check-input {
  margin: 0;
  box-shadow: none;
  border-radius: 0px;
  padding: 6px 7px;
  border-radius: 2px;
}
.blog-details__left-item .post-comment .post-comment-form .form-check .form-check-input:checked {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.blog-details__left-item .item {
  box-shadow: var(--shadow);
  border-radius: 10px;
  overflow: hidden;
}
.blog-details__left-item .author-text {
  padding: 40px;
}
.blog-details__left-item .author-text h5 {
  border-bottom: 1px solid var(--primary-color);
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}
.blog-details__left-item .author-text p {
  max-width: 690px;
  margin: 0 auto;
  padding: 10px 0;
}
.blog-details__left-item .content {
  padding: 40px;
}
.blog-details__left-item .content ul {
  display: flex;
  align-items: center;
  gap: 30px;
}
.blog-details__left-item .content ul li {
  display: flex;
  align-items: center;
  gap: 7px;
}
@media (max-width: 575px) {
  .blog-details__left-item .content {
    padding: 20px;
  }
  .blog-details__left-item .content ul {
    gap: 10px;
  }
  .blog-details__left-item .content ul li {
    gap: 5px;
  }
  .blog-details__left-item .content ul li span {
    font-size: 12px;
  }
}
.blog-details__right-item .item {
  padding: 40px;
  border-radius: 10px;
  box-shadow: var(--shadow);
}
.blog-details__right-item .item .title {
  border-bottom: var(--border-1px);
  margin-bottom: 30px;
}
.blog-details__right-item .item .title h4 {
  border-bottom: 1px solid var(--primary-color);
  display: inline-block;
  margin-bottom: -1px;
  padding-bottom: 5px;
  text-transform: capitalize;
}
.blog-details__right-item .item .post-search {
  position: relative;
}
.blog-details__right-item .item .post-search input, .blog-details__right-item .item .post-search .subscribe__item form textarea, .subscribe__item form .blog-details__right-item .item .post-search textarea, .blog-details__right-item .item .post-search .blog-details__left-item .post-comment .post-comment-form form textarea, .blog-details__left-item .post-comment .post-comment-form form .blog-details__right-item .item .post-search textarea {
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: var(--sub-bg);
}
.blog-details__right-item .item .post-search button {
  color: var(--white);
  padding: 10px 20px;
  background-color: var(--primary-color);
  border-radius: 10px;
  position: absolute;
  right: 0;
  top: 0;
}
.blog-details__right-item .item .category li a {
  padding: 10px 20px;
  border-radius: 10px;
  background-color: var(--sub-bg);
  display: block;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blog-details__right-item .item .category li a span {
  color: var(--paragraph);
}
.blog-details__right-item .item .category li a span i {
  color: var(--secondary-color);
  margin-right: 5px;
  transition: var(--transition);
}
.blog-details__right-item .item .category li a:hover span i {
  color: var(--primary-color);
}
.blog-details__right-item .item .recent-post li {
  display: flex;
  align-items: center;
  gap: 10px;
}
.blog-details__right-item .item .recent-post li .image {
  max-width: 110px;
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.blog-details__right-item .item .recent-post li .image::after {
  position: absolute;
  content: "";
  background-color: var(--primary-color);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: var(--transition);
}
.blog-details__right-item .item .recent-post li .image i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--white);
  z-index: 1;
  transition: var(--transition);
  opacity: 0;
  margin-left: -10px;
  visibility: hidden;
}
.blog-details__right-item .item .recent-post li .con h5 {
  margin-bottom: 5px;
}
.blog-details__right-item .item .recent-post li:hover .image i {
  opacity: 1;
  margin-left: 0;
  visibility: visible;
}
.blog-details__right-item .item .recent-post li:hover .image::after {
  opacity: 0.7;
}
.blog-details__right-item .item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.blog-details__right-item .item .tags a {
  padding: 5px 20px;
  border-radius: 5px;
  border: var(--border-1px);
  color: var(--span);
  font-size: 14px;
}
.blog-details__right-item .item .tags a:hover {
  color: var(--primary-color);
}

.blog-two__content {
  flex-basis: 52%;
}
.blog-two__item {
  padding: 30px;
  border-radius: 10px;
  box-shadow: var(--shadow);
  display: flex;
  align-items: center;
  gap: 30px;
}
.blog-two__item .btn-three {
  border: 1px solid var(--primary-color);
}
@media (max-width: 500px) {
  .blog-two__item {
    flex-direction: column;
  }
}
.blog-two__item .image {
  overflow: hidden;
  border-radius: 10px;
  max-width: 270px;
  position: relative;
}
.blog-two__item .image img {
  transition: var(--transition);
}
.blog-two__item .image .blog-tag {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: var(--primary-color);
  color: var(--white);
}
.blog-two__item:hover img {
  transform: scale(1.04);
}

.join-us-area {
  margin-bottom: -142px;
  position: relative;
  z-index: 1;
}
.join-us__item {
  padding: 80px 60px;
  background-color: var(--primary-color);
  border-radius: 10px;
}
@media (max-width: 575px) {
  .join-us__item {
    padding: 30px;
  }
}
.join-us__input {
  position: relative;
}
.join-us__input input, .join-us__input .subscribe__item form textarea, .subscribe__item form .join-us__input textarea, .join-us__input .blog-details__left-item .post-comment .post-comment-form form textarea, .blog-details__left-item .post-comment .post-comment-form form .join-us__input textarea {
  width: 100%;
  padding: 20px 22px;
  border-radius: 10px;
}
.join-us__input button {
  padding: 15px 30px;
  color: var(--white);
  font-weight: 700;
  background-color: var(--secondary-color);
  border-radius: 10px;
  position: absolute;
  right: 5px;
  top: 5px;
  transition: var(--transition);
}
.join-us__input button:hover {
  opacity: 0.8;
}

.talk-us__container {
  max-width: 1274px;
}
.talk-us__left {
  max-width: 560px;
}
.talk-us__now {
  background-color: var(--sub-bg);
  box-shadow: none;
}
.talk-us__item {
  display: flex;
  align-items: center;
  gap: 20px;
}
.talk-us__item h2 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--black);
  color: transparent;
}
.talk-us__item h2 span {
  color: transparent;
}
@media (max-width: 575px) {
  .talk-us__item .icon {
    width: 40px;
  }
  .talk-us__item .icon img {
    width: 100%;
  }
  .talk-us__item .content p {
    font-size: 13px;
    line-height: 16px;
  }
}

.process__content {
  padding: 30px;
  position: relative;
}
.process__content h3 {
  position: absolute;
  top: 25px;
  right: 20px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--span);
  color: transparent;
}
.process__item {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: var(--shadow);
  background-color: var(--white);
}
.process-two {
  position: relative;
  overflow: hidden;
}
.process-two__shape {
  position: absolute;
  top: -50px;
  left: 15%;
  z-index: -1;
}
.process-two__item {
  position: relative;
  overflow: hidden;
  background-color: var(--white);
  border: var(--border-1px);
  border-radius: 5px;
  transition: var(--transition);
  padding: 40px;
}
.process-two__item::after {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: var(--primary-color);
  content: "";
  transition: var(--transition);
}
.process-two__item .number {
  font-size: 26px;
  font-weight: 700;
  display: inline-block;
  color: var(--heading-color);
  opacity: 10%;
  position: absolute;
  right: 40px;
  top: 50px;
}
.process-two__item .number::after {
  position: absolute;
  content: "";
  left: -55px;
  top: 15px;
  width: 45px;
  height: 1px;
  background-color: var(--heading-color);
}
.process-two__item .icon {
  width: 85px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  border: 1px dashed var(--border);
  border-radius: 5px;
  transition: var(--transition);
}
.process-two__item:hover {
  box-shadow: var(--shadow);
  border: 1px solid transparent;
}
.process-two__item:hover .icon {
  border: 1px dashed var(--primary-color);
}
.process-two__item:hover::after {
  width: 100%;
  left: 0;
}

.brand-two-area {
  padding-top: 250px;
  margin-top: -156px;
}

.faq__item {
  max-width: 650px;
  padding-left: 40px;
  padding-top: 60px;
}
@media (max-width: 1399px) {
  .faq__item {
    padding-left: 0;
  }
}
@media (max-width: 991px) {
  .faq__item {
    padding: 0 30px;
  }
}
.faq-two__item .accordion .accordion-item {
  border: none;
  border-radius: 10px;
  margin-bottom: 30px;
  overflow: hidden;
}
.faq-two__item .accordion .accordion-item h2 button {
  font-size: 15px;
  line-height: 28px;
  font-weight: 700;
  box-shadow: none;
  border-radius: 0 !important;
  padding: 20px 30px;
}
.faq-two__item .accordion .accordion-item .accordion-body {
  padding: 30px;
  background-color: var(--sub-bg);
}
.faq-two__item .accordion .accordion-button {
  background-color: var(--primary-color);
  color: var(--white);
}
.faq-two__item .accordion .accordion-button::before {
  position: absolute;
  content: "\f068";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  top: 20px;
  right: 25px;
  font-size: 15px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 10px;
  color: var(--primary-color);
  background-color: var(--white);
  text-align: center;
  transition: var(--transition);
}
.faq-two__item .accordion .accordion-button.collapsed {
  background-color: var(--sub-bg);
  color: var(--heading-color);
  border-radius: 10px;
}
.faq-two__item .accordion .accordion-button.collapsed::before {
  content: "+";
  background-color: var(--sub-bg);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.faq-two__search {
  max-width: 850px;
  margin: 0 auto;
  margin-bottom: 40px;
  position: relative;
}
.faq-two__search input, .faq-two__search .subscribe__item form textarea, .subscribe__item form .faq-two__search textarea, .faq-two__search .blog-details__left-item .post-comment .post-comment-form form textarea, .blog-details__left-item .post-comment .post-comment-form form .faq-two__search textarea {
  padding: 10px 30px;
  width: 100%;
  border-radius: 10px;
  border: var(--border-1px);
}
.faq-two__search button {
  position: absolute;
  right: 30px;
  top: 11px;
  color: var(--paragraph);
}

.contact__form {
  background-color: var(--sub-bg);
  box-shadow: unset;
}
.contact__form h5 {
  border-bottom: 1px solid var(--primary-color);
  display: inline-block;
}
.contact__content .icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  border-radius: 10px;
  background-color: var(--primary-color);
}
@media (max-width: 575px) {
  .contact__content {
    border-right: none;
  }
}
.contact__map iframe {
  width: 100%;
  height: 285px;
  border-radius: 10px;
}

.error {
  text-align: center;
  overflow: hidden;
}
.error h1 {
  font-size: 300px;
  color: var(--secondary-color);
  font-weight: 500;
  line-height: 250px;
}
@media (max-width: 991px) {
  .error h1 {
    font-size: 100px;
    font-weight: 700;
    line-height: 100px;
  }
}

.footer-area {
  position: relative;
  padding-top: 140px;
}
.footer__bg {
  position: absolute;
  right: 0;
  bottom: 0;
}
.footer__wrp {
  position: relative;
}
.footer__item .footer-subs {
  position: relative;
}
.footer__item .footer-subs input, .footer__item .footer-subs .subscribe__item form textarea, .subscribe__item form .footer__item .footer-subs textarea, .footer__item .footer-subs .blog-details__left-item .post-comment .post-comment-form form textarea, .blog-details__left-item .post-comment .post-comment-form form .footer__item .footer-subs textarea {
  width: 100%;
  border-radius: 10px;
  border: 2px solid var(--black-sub);
  color: var(--white);
  padding: 10px 20px;
  background-color: transparent;
}
.footer__item .footer-subs input:focus, .footer__item .footer-subs .subscribe__item form textarea:focus, .subscribe__item form .footer__item .footer-subs textarea:focus, .footer__item .footer-subs .blog-details__left-item .post-comment .post-comment-form form textarea:focus, .blog-details__left-item .post-comment .post-comment-form form .footer__item .footer-subs textarea:focus {
  border: 2px solid var(--primary-color);
}
.footer__item .footer-subs button {
  padding: 7px 15px;
  color: var(--white);
  background-color: var(--primary-color);
  font-size: 14px;
  border-radius: 7px;
  position: absolute;
  right: 5px;
  top: 5px;
}
.footer__item .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  transition: var(--transition);
  border-radius: 10px;
  color: var(--white);
  background-color: var(--black-sub);
  margin-right: 5px;
}
.footer__item .social-icons a:hover {
  color: var(--primary-color);
}
.footer__item .social-icons .active {
  color: var(--primary-color);
}
.footer__item ul li a {
  color: var(--white);
}
.footer__item ul li a:hover {
  color: var(--primary-color);
}
.footer__item ul li a i {
  color: var(--primary-color);
  margin-right: 5px;
}
@media (max-width: 767px) {
  .footer__item.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }
}
.footer__item.service ul li a:hover {
  padding-left: 5px;
}
.footer__item.find ul li a {
  display: flex;
}
.footer__item.find ul li a i {
  font-size: 6px;
  margin-top: 10px;
  padding-right: 5px;
}
.footer__copy-text {
  padding: 15px 0;
  border-top: 1px solid var(--black-sub);
}
.footer__copy-text p {
  color: var(--white);
  opacity: 0.8;
}
.footer__copy-text p a {
  color: var(--white);
  font-weight: 500;
}
.footer__copy-text p a:hover {
  color: var(--primary-color);
}
.footer__copy-text ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.footer__copy-text ul li:not(:last-child) {
  margin-right: 30px;
}
@media (max-width: 767px) {
  .footer__copy-text ul li:not(:last-child) {
    margin-right: 10px;
  }
}
.footer__copy-text ul li a {
  color: var(--white);
  opacity: 0.8;
}
@media (max-width: 767px) {
  .footer__copy-text .copy-text {
    flex-direction: column;
  }
}
.footer-one {
  padding-top: 0;
}
.footer__gallery .footer-popup {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.footer__gallery .footer-popup .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: var(--primary-color);
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  background-color: var(--white);
  text-align: center;
  transition: var(--transition);
  opacity: 0;
}
.footer__gallery .footer-popup::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(74, 171, 61, 0.7);
  transition: var(--transition);
  opacity: 0;
}
.footer__gallery .footer-popup:hover .icon {
  opacity: 1;
}
.footer__gallery .footer-popup:hover::after {
  opacity: 1;
}

@-webkit-keyframes video-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
@keyframes video-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
body {
  font-size: 16px;
}

table {
  width: 100%;
  text-align: center;
}
table td {
  border: 1px solid #DDD;
  padding: 10px;
  text-align: left;
}
table tr.highlighted td {
  background: var(--primary-color);
  color: var(--bs-white);
}
table tr.highlighted td * {
  color: var(--bs-white);
}
table tr.highlighted-secondary td {
  background: var(--bs-gray);
  color: var(--bs-white);
}
table tr.highlighted-secondary td * {
  color: var(--bs-white);
}
table table tr td {
  border: 0;
  border-bottom: 1px solid #DDD;
  border-right: 1px solid #DDD;
}
table table tr td:last-child {
  border-right: 0;
}
table table tr:last-child td {
  border-bottom: 0;
  border-right: 0;
}

.gs-table > tbody > tr:nth-child(even) > td {
  background-color: #f9f9f9;
}

