// Button one area start here ***
.btn-one {
	padding: 10px 30px;
	border-radius: 50px;
	font-size: 15px;
	font-weight: 700;
	color: var(--white);
	background-color: var(--primary-color);
	display: inline-block;
	position: relative;
	letter-spacing: 0.5px;
	z-index: 3;
	overflow: hidden;
	text-transform: capitalize;
	@include breakpoint(max-xxsmall) {
		padding: 8px 16px;
		font-size: 14px;
	}
	&::before {
		content: "";
		position: absolute;
		height: 400px;
		width: 430px;
		top: 50%;
		left: 50%;
		background: var(--white);
		border-radius: 50%;
		transform: translateY(-50%) translateX(-50%) scale(0);
		transition: all 0.4s ease-out 0s;
		z-index: -1;
	}
	&:hover {
		color: var(--primary-color);
		&::before {
			transform: translateY(-50%) translateX(-50%) scale(1);
		}
	}
}

.btn-right {
	margin-left: auto
}
// Button one area end here ***

// Button two area start here ***
.btn-two {
	@extend.btn-one;
	&::before {
		background: var(--black);
	}
	&:hover {
		color: var(--white);
	}
}
// Button two area end here ***

// Button two area start here ***
.btn-three {
	@extend.btn-one;
	color: var(--black);
	border: var(--border-1px);
	background-color: transparent;
	&::before {
		background: var(--primary-color);
	}
	&:hover {
		color: var(--white);
	}
}
// Button two area end here ***

// Button read more area start here ***
.more-btn {
	position: relative;
	font-size: 15px;
	font-weight: 600;
	display: inline-block;
	letter-spacing: 0.5px;
	i {
		color: var(--primary-color);
	}
	&::after {
		position: absolute;
		content: "";
		width: 0%;
		height: 1px;
		background-color: var(--primary-color);
		left: 0;
		bottom: 0;
		transition: var(--transition);
	}
	&:hover {
		&::after {
			width: 78%;
		}
	}
}
// Button read more area Button arry

// Arry button area start here ***
.arry-prev {
	@include radius(60px, 60px, 60px);
	background-color: rgba(73, 169, 61, 0.1);
	color: var(--primary-color);
	transition: var(--transition);
	&:hover {
		background-color: var(--primary-color);
		color: var(--white);
	}
	&.active {
		background-color: var(--primary-color);
		color: var(--white);
	}
}
.arry-next {
	@extend.arry-prev;
}
// Arry button area end here ***

// Dots area start here ***
.dot {
	.swiper-pagination-bullet {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		transition: 0.6s;
		background-color: transparent;
		opacity: 1;
		position: relative;
		border: 1px solid transparent;
		&::before {
			position: absolute;
			content: "";
			top: 5px;
			left: 5px;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: var(--primary-color);
			transition: 0.6s;
		}
	}
	.swiper-pagination-bullet.swiper-pagination-bullet-active {
		&::before {
			background-color: var(--primary-color);
		}
		border: 1px solid var(--primary-color);
	}
}
// Dots area end here ***

// Video button area start here ***
.video-btn {
	position: relative;
	text-align: center;
	display: inline-block;
	z-index: 2;
	a {
		position: relative;
		color: var(--white);
		font-size: 28px;
		z-index: 1;
		background-color: var(--primary-color);
		width: 90px;
		height: 90px;
		line-height: 90px;
		border-radius: 50%;
		display: block;
		transition: 0.4s;
	}
}
.video-pulse::after,
.video-pulse::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	border: 1px solid var(--primary-color);
	opacity: 0.7;
	left: 0;
	top: 0;
	border-radius: 50%;
	-webkit-animation-duration: 2.5s;
	animation-duration: 2.5s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-name: video-animation;
	animation-name: video-animation;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}
.video-pulse::before {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}
// Video button area end here ***

// Back to top button area start here ***
.scroll-up {
	cursor: pointer;
	display: block;
	border-radius: 50px;
	box-shadow: inset 0 0 0 2px var(--border);
	z-index: 99;
	opacity: 0;
	visibility: hidden;
	transform: translateY(15px);
	position: fixed;
	right: 25px;
	bottom: 35px;
	height: 50px;
	width: 50px;
	transition: var(--transition);
	&::after {
		position: absolute;
		font-family: "Font Awesome 6 Pro";
		content: "\f30c";
		text-align: center;
		line-height: 50px;
		font-weight: 700;
		font-size: 18px;
		color: var(--primary-color);
		left: 0;
		top: 0;
		height: 50px;
		width: 50px;
		cursor: pointer;
		display: block;
		z-index: 1;
		transition: var(--transition);
	}
	svg path {
		fill: none;
	}
	svg.scroll-circle path {
		stroke: var(--primary-color);
		stroke-width: 4px;
		box-sizing: border-box;
		transition: var(--transition);
	}
	&.active-scroll {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}
// Back to top button area end here ***
